var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"image-disabled "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_editable_by_current_user"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":77}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-hover-background\"></div>\n        <span class=\"image-disabled-text flex\" aria-label=\""
    + alias3(alias2(alias1(depth0, "disabled_text", {"start":{"line":5,"column":62},"end":{"line":5,"column":75}} ), depth0))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias3(alias2(alias1(depth0, "disabled_text", {"start":{"line":6,"column":15},"end":{"line":6,"column":28}} ), depth0))
    + "\n        </span>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias3(alias2(alias1(depth0, "widget", {"start":{"line":1,"column":11},"end":{"line":1,"column":17}} ), depth0))
    + "-upload-widget\" class=\"inline-block image_upload_widget\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"disabled_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"image_upload_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_editable_by_current_user"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":90}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-hover-background\"></div>\n        <button class=\"image-delete-button\" aria-label=\""
    + alias3(alias2(alias1(depth0, "delete_text", {"start":{"line":12,"column":59},"end":{"line":12,"column":70}} ), depth0))
    + "\" role=\"button\" tabindex=\"0\">\n            &times;\n        </button>\n        <span class=\"image-delete-text\" aria-label=\""
    + alias3(alias2(alias1(depth0, "delete_text", {"start":{"line":15,"column":55},"end":{"line":15,"column":66}} ), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias3(alias2(alias1(depth0, "delete_text", {"start":{"line":16,"column":15},"end":{"line":16,"column":26}} ), depth0))
    + "\n        </span>\n        <span class=\"image-upload-text\" aria-label=\""
    + alias3(alias2(alias1(depth0, "upload_text", {"start":{"line":18,"column":55},"end":{"line":18,"column":66}} ), depth0))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias3(alias2(alias1(depth0, "upload_text", {"start":{"line":19,"column":15},"end":{"line":19,"column":26}} ), depth0))
    + "\n        </span>\n        <span class=\"upload-spinner-background\">\n            <img class=\"image_upload_spinner\" src=\"" + require("../../images/loading/tail-spin.svg") + "\" alt=\"\" />\n        </span>\n    </div>\n    <img class=\"image-block\" src=\""
    + alias3(alias2(alias1(depth0, "image", {"start":{"line":25,"column":37},"end":{"line":25,"column":42}} ), depth0))
    + "\"/>\n    <input type=\"file\" name=\"file_input\" class=\"notvisible image_file_input\"  value=\""
    + alias3(alias2(alias1(depth0, "upload_text", {"start":{"line":26,"column":88},"end":{"line":26,"column":99}} ), depth0))
    + "\" />\n    <div class=\"image_file_input_error text-error\"></div>\n</div>\n";
},"useData":true});