var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"inbox-main\">\n    <div class=\"search_group\" id=\"inbox-filters\" role=\"group\">\n        "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":"inbox-filter"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <i class=\"zulip-icon zulip-icon-search-inbox\"></i>\n        <input type=\"text\" id=\""
    + alias3(alias2(alias1(depth0, "INBOX_SEARCH_ID", {"start":{"line":5,"column":33},"end":{"line":5,"column":48}} ), depth0))
    + "\" value=\""
    + alias3(alias2(alias1(depth0, "search_val", {"start":{"line":5,"column":61},"end":{"line":5,"column":71}} ), depth0))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":107},"end":{"line":5,"column":122}}}))
    + "\" />\n        <button id=\"inbox-clear-search\">\n            <i class=\"zulip-icon zulip-icon-close-small\"></i>\n        </button>\n    </div>\n    <div id=\"inbox-empty-with-search\" class=\"inbox-empty-text empty-list-message\">\n        "
    + alias3(lookupProperty(helpers,"t").call(alias4,"No conversations match your filters.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":52}}}))
    + "\n    </div>\n    "
    + ((stack1 = container.invokePartial(require("./inbox_no_unreads.hbs"),undefined,{"name":"inbox_no_unreads","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div id=\"inbox-list\">\n        "
    + ((stack1 = container.invokePartial(require("./inbox_list.hbs"),depth0,{"name":"inbox_list","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});