var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"emoji-popover-subheading\" data-section=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":2,"column":58},"end":{"line":2,"column":62}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "translated", {"start":{"line":2,"column":68},"end":{"line":2,"column":78}} ), depth0))
    + "</div>\n    <div class=\"emoji-collection\" data-section=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":3,"column":50},"end":{"line":3,"column":54}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emojis"),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./emoji_popover_emoji.hbs"),undefined,{"name":"emoji_popover_emoji","hash":{"emoji_dict":depth0,"is_status_emoji_popover":lookupProperty(depths[2],"is_status_emoji_popover"),"message_id":lookupProperty(depths[2],"message_id"),"index":lookupProperty(data,"index"),"section":lookupProperty(container.data(data, 1),"index"),"type":"emoji_picker_emoji"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_categories"),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});