var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"input-group\" id=\"integration-url-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":1,"column":47},"end":{"line":1,"column":50}} ), depth0))
    + "-container\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" id=\"integration-url-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":3,"column":53},"end":{"line":3,"column":56}} ), depth0))
    + "-checkbox\" class=\"integration-url-parameter\" />\n        <span class=\"rendered-checkbox\"></span>\n    </label>\n    <label class=\"inline\" for=\"integration-url-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":6,"column":49},"end":{"line":6,"column":52}} ), depth0))
    + "-checkbox\">"
    + alias3(alias2(alias1(depth0, "label", {"start":{"line":6,"column":67},"end":{"line":6,"column":72}} ), depth0))
    + "</label>\n</div>\n";
},"useData":true});