var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-popover-emoji "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_reacted"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":65}}})) != null ? stack1 : "")
    + "\" data-emoji-name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"emoji_name"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":84},"end":{"line":2,"column":139}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-emoji-id=\""
    + alias4(alias3(alias2(depths[1], "type", {"start":{"line":2,"column":171},"end":{"line":2,"column":178}} ), depth0))
    + ","
    + alias4(alias3(alias2(depths[1], "section", {"start":{"line":2,"column":183},"end":{"line":2,"column":193}} ), depth0))
    + ","
    + alias4(alias3(alias2(depths[1], "index", {"start":{"line":2,"column":198},"end":{"line":2,"column":206}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_realm_emoji"),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "reacted";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "emoji_name", {"start":{"line":2,"column":104},"end":{"line":2,"column":114}} ), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":2,"column":126},"end":{"line":2,"column":130}} ), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    return "    <img src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "url", {"start":{"line":4,"column":16},"end":{"line":4,"column":19}} ), depth0))
    + "\" class=\"emoji\"/>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_code", {"start":{"line":6,"column":30},"end":{"line":6,"column":40}} ), depth0))
    + "\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_dict"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});