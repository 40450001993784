var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <br />\n            <i>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "tooltip_second_line", {"start":{"line":7,"column":17},"end":{"line":7,"column":36}} ), depth0))
    + "</i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"information_density_tooltip_template\">\n    <div class=\"tooltip-inner-content\">\n        <span>\n            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "tooltip_first_line", {"start":{"line":4,"column":14},"end":{"line":4,"column":32}} ), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"tooltip_second_line"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n</div>\n";
},"useData":true});