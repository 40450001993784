var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label class=\"preferences-radio-choice-label\">\n                    <div class=\"radio-choice-controls\">\n                        <input type=\"radio\" class=\"setting_emojiset_choice\" name=\"emojiset\" value=\""
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":13,"column":101},"end":{"line":13,"column":109}} ), depth0))
    + "\"/>\n                        <span class=\"preferences-radio-choice-text\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":14,"column":70},"end":{"line":14,"column":79}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"key"),"google-blob",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":30},"end":{"line":15,"column":57}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"key"),"text",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":30},"end":{"line":21,"column":50}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":28,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </label>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span>(<em>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"deprecated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":35},"end":{"line":16,"column":54}}}))
    + "</em>)</span>\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/emoji-and-emoticons#change-your-emoji-set"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"emoji_alt_code\">&nbsp;:relaxed:</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                        <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":24,"column":81},"end":{"line":24,"column":89}} ), depth0))
    + "-64/1f642.png\" />\n                        <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":25,"column":81},"end":{"line":25,"column":89}} ), depth0))
    + "-64/1f44d.png\" />\n                        <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":26,"column":81},"end":{"line":26,"column":89}} ), depth0))
    + "-64/1f680.png\" />\n                        <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":27,"column":81},"end":{"line":27,"column":89}} ), depth0))
    + "-64/1f389.png\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-preferences "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"subsection-header\">\n        <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":40}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":127}}}),"section_name":"emoji-preferences-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"input-group\">\n        <label class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":64}}}))
    + "</label>\n        <div class=\"emojiset_choices grey-box prop-element\" id=\""
    + alias2(container.lambda(container.strict(depth0, "prefix", {"start":{"line":9,"column":66},"end":{"line":9,"column":72}} ), depth0))
    + "emojiset\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"settings_object"),"emojiset_choices"),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":31,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"help_link":"/help/configure-emoticon-translations","label":lookupProperty(lookupProperty(depth0,"settings_label"),"translate_emoticons"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"translate_emoticons"),"setting_name":"translate_emoticons"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"display_emoji_reaction_users"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"display_emoji_reaction_users"),"setting_name":"display_emoji_reaction_users"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});