var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "reaction";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_status_emoji_popover"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":85},"end":{"line":1,"column":145}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "status";
},"6":function(container,depth0,helpers,partials,data) {
    return "composition";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"emoji-popover-tab-item "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(data,"first"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":81}}})) != null ? stack1 : "")
    + "\" data-tab-name='"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":100},"end":{"line":8,"column":104}} ), depth0))
    + "' title='"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":117},"end":{"line":8,"column":121}} ), depth0))
    + "'><i class=\"fa "
    + alias3(alias2(alias1(depth0, "icon", {"start":{"line":8,"column":140},"end":{"line":8,"column":144}} ), depth0))
    + "\"></i></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " active ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-picker-popover\" data-emoji-destination=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"message_id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":152}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"emoji-popover\">\n        <div class=\"popover-filter-input-wrapper\">\n            <input id=\"emoji-popover-filter\" class=\"popover-filter-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":136},"end":{"line":4,"column":151}}}))
    + "\" autofocus/>\n        </div>\n        <div class=\"emoji-popover-category-tabs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"emoji_categories"),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"emoji-popover-emoji-map\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2(alias4(alias3(depth0, "message_id", {"start":{"line":11,"column":142},"end":{"line":11,"column":152}} ), depth0))
    + "\">\n        </div>\n        <div class=\"emoji-search-results-container\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2(alias4(alias3(depth0, "message_id", {"start":{"line":13,"column":149},"end":{"line":13,"column":159}} ), depth0))
    + "\">\n            <div class=\"emoji-popover-results-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":55},"end":{"line":14,"column":78}}}))
    + "</div>\n            <div class=\"emoji-search-results\"></div>\n        </div>\n    </div>\n    <div class=\"emoji-showcase-container\"></div>\n</div>\n";
},"useData":true});