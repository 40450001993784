var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A deactivated emoji will remain visible in existing messages and emoji reactions, but cannot be used on new messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":128}}}))
    + "</p>\n<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This action cannot be undone.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":40}}}))
    + "</p>\n\n";
},"useData":true});