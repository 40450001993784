var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Press <z-shortcut></z-shortcut> to send\n                                \n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"Enter",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":89}}}));
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Press <z-shortcut></z-shortcut> to add a new line\n                                \n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"Ctrl","Enter",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":17,"column":57},"end":{"line":17,"column":96}}}));
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Press <z-shortcut></z-shortcut> to send\n                                \n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Press <z-shortcut></z-shortcut> to add a new line\n                                \n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"send_later_selected_send_later_time popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-calendar-clock\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Schedule for {formatted_send_later_time}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":49},"end":{"line":52,"column":98}}}))
    + "</span>\n            </a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"compose_new_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-file-check\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Save draft and start a new message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":49},"end":{"line":75,"column":92}}}))
    + "</span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"send_later_popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-menu-list-item\">\n            <div role=\"group\" class=\"enter_sends_choices\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Enter to send choices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":70},"end":{"line":4,"column":100}}}))
    + "\">\n                <label role=\"menuitemradio\" class=\"enter_sends_choice\" tabindex=\"0\">\n                    <input type=\"radio\" class=\"enter_sends_choice_radio\" name=\"enter_sends_choice\" value=\"true\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"enter_sends_true"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":111},"end":{"line":6,"column":151}}})) != null ? stack1 : "")
    + " />\n                    <div class=\"enter_sends_choice_text_container\">\n                        <span class=\"enter_sends_major enter_sends_choice_text\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":12,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                        <span class=\"enter_sends_minor enter_sends_choice_text\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":18,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </label>\n                <label role=\"menuitemradio\" class=\"enter_sends_choice\" tabindex=\"0\">\n                    <input type=\"radio\" class=\"enter_sends_choice_radio\" name=\"enter_sends_choice\" value=\"false\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"enter_sends_true"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":112},"end":{"line":23,"column":160}}})) != null ? stack1 : "")
    + " />\n                    <div class=\"enter_sends_choice_text_container\">\n                        <span class=\"enter_sends_major enter_sends_choice_text\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":29,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                        <span class=\"enter_sends_minor enter_sends_choice_text\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":35,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </label>\n            </div>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"open_send_later_modal popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-calendar\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Schedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":74}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"formatted_send_later_time"),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a href=\"#scheduled\" role=\"menuitem\" class=\"navigate-link-on-enter popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-calendar-days\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":49},"end":{"line":59,"column":81}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator drafts-item-in-popover\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item drafts-item-in-popover\">\n            <a href=\"#drafts\" role=\"menuitem\" class=\"view_contextual_drafts popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-drafts\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":49},"end":{"line":66,"column":69}}}))
    + "</span>\n                <span class=\"compose-drafts-count-container\"><span class=\"unread_count quiet-count compose-drafts-count\"></span></span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_compose_new_message"),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"3_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":100}}}) || fn;
  return fn;
  }

,"useDecorators":true,"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":17,"column":32},"end":{"line":17,"column":107}}}) || fn;
  return fn;
  }

,"9_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":107}}}) || fn;
  return fn;
  }

,"11_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":34,"column":100}}}) || fn;
  return fn;
  }

,"useData":true,"useDepths":true});