var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"copy_generated_link_container\">\n    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":24}}}))
    + "</span>\n    <a href=\""
    + alias2(alias4(alias3(depth0, "invite_link", {"start":{"line":3,"column":16},"end":{"line":3,"column":27}} ), depth0))
    + "\" id=\"multiuse_invite_link\">"
    + alias2(alias4(alias3(depth0, "invite_link", {"start":{"line":3,"column":61},"end":{"line":3,"column":72}} ), depth0))
    + "</a>\n\n    <span id=\"copy_generated_invite_link\" class=\"copy-button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":82},"end":{"line":5,"column":100}}}))
    + "\" data-tippy-placement=\"top\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":141},"end":{"line":5,"column":159}}}))
    + "\" data-clipboard-text=\""
    + alias2(alias4(alias3(depth0, "invite_link", {"start":{"line":5,"column":185},"end":{"line":5,"column":196}} ), depth0))
    + "\" role=\"button\">\n        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n    </span>\n</div>\n";
},"useData":true});