var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cannot-deactivate-group-banner main-view-banner error\">\n    <p class=\"banner-text\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"To deactivate this group, you must first remove all permissions assigned to it.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":95}}}))
    + "\n    </p>\n    <button class=\"permissions-button main-view-banner-action-button\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":32}}}))
    + "\n    </button>\n</div>\n";
},"useData":true});