var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_control_button failed_message_action\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":92}}}))
    + "\">\n    <i class=\"message-controls-icon fa fa-refresh refresh-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":86},"end":{"line":2,"column":100}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n</div>\n\n<div class=\"message_control_button failed_message_action\" data-tooltip-template-id=\"dismiss-failed-send-button-tooltip-template\">\n    <i class=\"message-controls-icon fa fa-times-circle remove-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dismiss",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":90},"end":{"line":6,"column":106}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n</div>\n";
},"useData":true});