var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":2,"column":39},"end":{"line":2,"column":41}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"search-container\">\n    <input type=\"text\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":51}}})) != null ? stack1 : "")
    + " class=\"search filter_text_input\" placeholder=\""
    + alias3(alias2(alias1(depth0, "placeholder", {"start":{"line":2,"column":100},"end":{"line":2,"column":111}} ), depth0))
    + "\" aria-label=\""
    + alias3(alias2(alias1(depth0, "aria_label", {"start":{"line":2,"column":129},"end":{"line":2,"column":139}} ), depth0))
    + "\"/>\n    <button type=\"button\" class=\"clear-filter\">\n        <i class=\"zulip-icon zulip-icon-close\"></i>\n    </button>\n</div>\n";
},"useData":true});