var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Messages must be scheduled at least {minimum_scheduled_message_delay_minutes} minutes in the future.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":124}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"main-view-banner success message_scheduled_success_compose_banner\"\n  data-scheduled-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "scheduled_message_id", {"start":{"line":3,"column":31},"end":{"line":3,"column":51}} ), depth0))
    + "\">\n    <div class=\"main-view-banner-elements-wrapper\">\n        <p class=\"banner_content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"minimum_scheduled_message_delay_minutes_note"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "            "
    + alias1(lookupProperty(helpers,"t").call(alias2,"Your message has been scheduled for {deliver_at}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":69}}}))
    + "\n            <a href=\"#scheduled\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"View scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":64}}}))
    + "</a>\n        </p>\n        <button class=\"main-view-banner-action-button undo_scheduled_message\" >"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Undo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":79},"end":{"line":12,"column":91}}}))
    + "</button>\n    </div>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});