var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"option-row\">\n    <i class=\"zulip-icon zulip-icon-grip-vertical drag-icon\"></i>\n    <input type=\"text\" class=\"poll-option-input modal_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New option",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":79},"end":{"line":3,"column":98}}}))
    + "\" />\n    <button type=\"button\" class=\"button rounded small delete-option\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":91}}}))
    + "\">\n        <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n    </button>\n</li>\n";
},"useData":true});