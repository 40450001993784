var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "deactivated";
},"3":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <div class=\"popover-menu-user-presence user-circle zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":6,"column":91},"end":{"line":6,"column":108}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":6,"column":113},"end":{"line":6,"column":130}} ), depth0))
    + " hidden-for-spectators\" data-presence-indicator-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":6,"column":191},"end":{"line":6,"column":198}} ), depth0))
    + "\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"popover-menu-user-presence conversation-partners-icon fa fa-ban fa-lg deactivated-user-icon user_circle\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_system_bot"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":29,"column":27}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"System bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":22,"column":48}}}))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":29},"end":{"line":24,"column":41}}}))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_type"),"Member",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":62}}}),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":27,"column":40}}})) != null ? stack1 : "")
    + "</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"lowercase\">("
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_type", {"start":{"line":26,"column":59},"end":{"line":26,"column":68}} ), depth0))
    + ")</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    <div>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_type", {"start":{"line":31,"column":28},"end":{"line":31,"column":37}} ), depth0))
    + "</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"text-item popover-menu-list-item hidden-for-spectators\">\n                <span class=\"personal-menu-status-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":48,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"status_text personal-menu-status-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_placeholder_for_status_text"),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":54,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":61,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":47,"column":31}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":42,"column":66},"end":{"line":42,"column":94}} ), depth0))
    + ":</span>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":47,"column":24}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img src=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":44,"column":40},"end":{"line":44,"column":61}} ), depth0))
    + "\" class=\"emoji status_emoji\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":44,"column":115},"end":{"line":44,"column":143}} ), depth0))
    + ":\"/>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji status_emoji emoji-"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":46,"column":68},"end":{"line":46,"column":96}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":46,"column":123},"end":{"line":46,"column":151}} ), depth0))
    + ":\"></span>\n                        ";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\"personal-menu-no-status-text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"No status text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":68},"end":{"line":51,"column":90}}}))
    + "</i>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":53,"column":30},"end":{"line":53,"column":41}} ), depth0))
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" tabindex=\"0\" class=\"personal-menu-clear-status user-card-clear-status-button popover-menu-link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":147},"end":{"line":58,"column":167}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":189},"end":{"line":58,"column":215}}}))
    + "\">\n                        <i class=\"personal-menu-clear-status-icon popover-menu-icon zulip-icon zulip-icon-x-circle\" aria-hidden=\"true\"></i>\n                    </a>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":79,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":80,"column":12},"end":{"line":94,"column":19}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":57},"end":{"line":69,"column":77}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":57},"end":{"line":76,"column":76}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_off popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-play-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Turn off invisible mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":57},"end":{"line":84,"column":89}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_on popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-stop-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go invisible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":57},"end":{"line":91,"column":78}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"unless","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":105,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_time"),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":12},"end":{"line":111,"column":19}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    return "                <li role=\"none\" class=\"popover-menu-list-item text-item hidden-for-spectators\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-past-time\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label user-last-seen-time\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_last_seen_time_status", {"start":{"line":103,"column":75},"end":{"line":103,"column":101}} ), depth0))
    + "</span>\n                </li>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item text-item hidden-for-spectators\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-clock\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"{user_time} local time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":53},"end":{"line":109,"column":84}}}))
    + "</span>\n                </li>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item italic hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":114,"column":16},"end":{"line":118,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":53},"end":{"line":115,"column":92}}}))
    + "</span>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This user has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":117,"column":53},"end":{"line":117,"column":93}}}))
    + "</span>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item\">\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Joined {date_joined}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":49},"end":{"line":123,"column":78}}}))
    + "</span>\n            </li>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bot_owner"),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":12},"end":{"line":133,"column":19}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item user-card-popover-bot-owner-field text-item hidden-for-spectators\">\n                    <span class=\"bot_owner\" data-tippy-content=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"bot_owner"), "full_name", {"start":{"line":130,"column":67},"end":{"line":130,"column":86}} ), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":91},"end":{"line":130,"column":109}}}))
    + ":</span>\n                    "
    + ((stack1 = container.invokePartial(require("../../user_display_only_pill.hbs"),undefined,{"name":"../../user_display_only_pill","hash":{"is_active":true,"img_src":lookupProperty(lookupProperty(depth0,"bot_owner"),"avatar_url"),"user_id":lookupProperty(lookupProperty(depth0,"bot_owner"),"user_id"),"display_value":lookupProperty(lookupProperty(depth0,"bot_owner"),"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </li>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"user_email"),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":12},"end":{"line":143,"column":19}}})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item text-item user-card-popover-email-field hidden-for-spectators\">\n                    <span class=\"user_popover_email\">"
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":138,"column":56},"end":{"line":138,"column":66}} ), depth0))
    + "</span>\n                    <span role=\"menuitem\" tabindex=\"0\" id=\"popover-menu-copy-email\" class=\"popover-menu-link copy-button hide_copy_icon\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":149},"end":{"line":139,"column":167}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":189},"end":{"line":139,"column":208}}}))
    + "\" data-clipboard-text=\""
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":139,"column":234},"end":{"line":139,"column":244}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-copy hide\" aria-hidden=\"true\"></i>\n                    </span>\n                </li>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":150,"column":53},"end":{"line":150,"column":79}}}))
    + "</span>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":53},"end":{"line":152,"column":74}}}))
    + "</span>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"popover-menu-link "
    + alias1(container.lambda(container.strict(depth0, "private_message_class", {"start":{"line":158,"column":63},"end":{"line":158,"column":84}} ), depth0))
    + "\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-send-dm\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Send direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":53},"end":{"line":160,"column":81}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":20},"end":{"line":163,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"R",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":162,"column":24},"end":{"line":162,"column":52}}}))
    + "\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_message_context"),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":169,"column":16},"end":{"line":189,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" class=\"popover-menu-link mention_user\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.program(74, data, 0),"data":data,"loc":{"start":{"line":172,"column":24},"end":{"line":176,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":24},"end":{"line":179,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n";
},"72":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":173,"column":61},"end":{"line":173,"column":90}}}))
    + "</span>\n";
},"74":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":61},"end":{"line":175,"column":91}}}))
    + "</span>\n";
},"76":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"@",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":178,"column":28},"end":{"line":178,"column":56}}}))
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" class=\"popover-menu-link copy_mention_syntax\" tabindex=\"0\" data-clipboard-text=\""
    + alias1(container.lambda(container.strict(depth0, "user_mention_syntax", {"start":{"line":182,"column":122},"end":{"line":182,"column":141}} ), depth0))
    + "\">\n                        <i class=\"popover-menu-icon fa zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Copy mention syntax",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":57},"end":{"line":184,"column":85}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":24},"end":{"line":187,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n";
},"80":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"popover-menu-link edit-your-profile\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-tool\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":196,"column":53},"end":{"line":196,"column":79}}}))
    + "</span>\n                </a>\n            </li>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View messages with yourself",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":53},"end":{"line":205,"column":89}}}))
    + "</span>\n";
},"84":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":207,"column":53},"end":{"line":207,"column":82}}}))
    + "</span>\n";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_mute"),{"name":"if","hash":{},"fn":container.program(87, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":12},"end":{"line":230,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_unmute"),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":231,"column":12},"end":{"line":242,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_manage_user"),{"name":"if","hash":{},"fn":container.program(97, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":243,"column":12},"end":{"line":267,"column":19}}})) != null ? stack1 : "");
},"87":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                    <a role=\"menuitem\" class=\"sidebar-popover-mute-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-hide\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.program(90, data, 0),"data":data,"loc":{"start":{"line":223,"column":24},"end":{"line":227,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                </li>\n";
},"88":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":224,"column":61},"end":{"line":224,"column":83}}}))
    + "</span>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":226,"column":61},"end":{"line":226,"column":84}}}))
    + "</span>\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                    <a role=\"menuitem\" class=\"sidebar-popover-unmute-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon fa fa-eye\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":235,"column":24},"end":{"line":239,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                </li>\n";
},"93":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":61},"end":{"line":236,"column":85}}}))
    + "</span>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":238,"column":61},"end":{"line":238,"column":86}}}))
    + "</span>\n";
},"97":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n                <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                    <a role=\"menuitem\" class=\"sidebar-popover-manage-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(98, data, 0),"inverse":container.program(100, data, 0),"data":data,"loc":{"start":{"line":248,"column":24},"end":{"line":252,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_active"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":255,"column":22},"end":{"line":255,"column":37}}}),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":255,"column":16},"end":{"line":266,"column":23}}})) != null ? stack1 : "");
},"98":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":61},"end":{"line":249,"column":85}}}))
    + "</span>\n";
},"100":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":251,"column":61},"end":{"line":251,"column":86}}}))
    + "</span>\n";
},"102":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                        <a role=\"menuitem\" class=\"sidebar-popover-reactivate-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                            <i class=\"popover-menu-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(103, data, 0),"inverse":container.program(105, data, 0),"data":data,"loc":{"start":{"line":259,"column":28},"end":{"line":263,"column":35}}})) != null ? stack1 : "")
    + "                        </a>\n                    </li>\n";
},"103":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":260,"column":65},"end":{"line":260,"column":93}}}))
    + "</span>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":262,"column":65},"end":{"line":262,"column":94}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu user-card-popover-actions no-auto-hide-right-sidebar-overlay\" id=\"user_card_popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"popover-menu-user-header\">\n        <div class=\"popover-menu-user-avatar-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_active"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":96}}})) != null ? stack1 : "")
    + "\">\n            <img class=\"popover-menu-user-avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":89}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4(alias3(alias2(depth0, "user_avatar", {"start":{"line":4,"column":98},"end":{"line":4,"column":109}} ), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_active"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":45}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":46}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_active"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":33}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"popover-menu-user-info\">\n            <div class=\"popover-menu-user-full-name text-select\" data-tippy-content=\""
    + alias4(alias3(alias2(depth0, "user_full_name", {"start":{"line":13,"column":87},"end":{"line":13,"column":101}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("../../user_full_name.hbs"),undefined,{"name":"../../user_full_name","hash":{"name":lookupProperty(depth0,"user_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"popover-menu-user-type\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <ul role=\"menu\" class=\"popover-menu-list\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":36,"column":62},"end":{"line":36,"column":69}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":95,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_me"),lookupProperty(depth0,"status_content_available"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":96,"column":14},"end":{"line":96,"column":49}}}),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":8},"end":{"line":98,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":120,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"spectator_view"),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":8},"end":{"line":125,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":134,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":8},"end":{"line":144,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./user_card_popover_custom_fields.hbs"),undefined,{"name":"./user_card_popover_custom_fields","hash":{"profile_fields":lookupProperty(depth0,"display_profile_fields")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"popover-menu-link view_full_user_profile\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-account\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data,"loc":{"start":{"line":149,"column":16},"end":{"line":153,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_send_private_message"),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":8},"end":{"line":166,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_me"),{"name":"unless","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":8},"end":{"line":191,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":8},"end":{"line":199,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item link-item\">\n            <a role=\"menuitem\" href=\""
    + alias4(alias3(alias2(depth0, "pm_with_url", {"start":{"line":202,"column":40},"end":{"line":202,"column":51}} ), depth0))
    + "\" class=\"narrow_to_private_messages popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-user\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.program(84, data, 0),"data":data,"loc":{"start":{"line":204,"column":16},"end":{"line":208,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n        <li role=\"none\" class=\"popover-menu-list-item link-item\">\n            <a role=\"menuitem\" href=\""
    + alias4(alias3(alias2(depth0, "sent_by_url", {"start":{"line":212,"column":40},"end":{"line":212,"column":51}} ), depth0))
    + "\" class=\"narrow_to_messages_sent popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-message-square\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":214,"column":49},"end":{"line":214,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_manage_section"),{"name":"if","hash":{},"fn":container.program(86, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":217,"column":8},"end":{"line":268,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});