var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<a class=\"message-link\" href=\""
    + alias3(alias2(alias1(depth0, "href", {"start":{"line":2,"column":32},"end":{"line":2,"column":36}} ), depth0))
    + "\">#"
    + alias3(alias2(alias1(depth0, "channel_name", {"start":{"line":4,"column":7},"end":{"line":4,"column":19}} ), depth0))
    + " &gt; <span class=\"empty-topic-display\">"
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":4,"column":63},"end":{"line":4,"column":81}} ), depth0))
    + "</span> @ 💬</a>";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<a class=\"message-link\" href=\""
    + alias3(alias2(alias1(depth0, "href", {"start":{"line":8,"column":32},"end":{"line":8,"column":36}} ), depth0))
    + "\">#"
    + alias3(alias2(alias1(depth0, "channel_name", {"start":{"line":10,"column":7},"end":{"line":10,"column":19}} ), depth0))
    + " &gt; "
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":10,"column":29},"end":{"line":10,"column":47}} ), depth0))
    + " @ 💬</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":8}}})) != null ? stack1 : "");
},"useData":true});