var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/help/contact-support\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-life-buoy\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Contact support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":49},"end":{"line":38,"column":73}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_admin"),lookupProperty(depth0,"is_owner"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":41,"column":14},"end":{"line":41,"column":36}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/contact-sales\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-handshake\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Contact sales",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":71}}}))
    + "</span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"help-menu-dropdown\" aria-labelledby=\"help-menu\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/help/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-help\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":69}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"keyboard-shortcuts\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-keyboard\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":76}}}))
    + "</span>\n                "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"?",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":44}}}))
    + "\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"message-formatting\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"search-operators\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-manage-search\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search filters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":72}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\" id=\"gear_menu_about_zulip\">\n            <a role=\"menuitem\" href=\"#about-zulip\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-info\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"About Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":69}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"corporate_enabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});