var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"type":"submit","intent":"brand","attention":"quiet","label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":41}}}),"id":"show-add-default-streams-modal"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-default-channels-list\" class=\"settings-section\" data-name=\"default-channels-list\">\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure the default channels new users are subscribed to when joining your organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":106}}}))
    + "</p>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":36}}}))
    + "</h3>\n        <div class=\"add_default_streams_button_container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter default channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":70},"end":{"line":16,"column":99}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":58}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":80},"end":{"line":23,"column":93}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no default channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":70}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No default channels match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":99},"end":{"line":30,"column":154}}}))
    + "\"\n              id=\"admin_default_streams_table\" class=\"admin_default_stream_table\"></tbody>\n        </table>\n    </div>\n\n    <div id=\"admin_page_default_streams_loading_indicator\"></div>\n</div>\n";
},"usePartial":true,"useData":true});