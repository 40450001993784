var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"bot-settings-tip\" id=\"personal-bot-settings-tip\">\n        </div>\n        <div>\n            "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"hidden":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_create_new_bots"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":19,"column":21},"end":{"line":19,"column":46}}}),"custom_classes":"add-a-new-bot","intent":"brand","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Add a new bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":39}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Looking for our <z-integrations>integrations</z-integrations> or <z-api>API</z-api> documentation?\n                \n                \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/integrations/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/api/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bot-settings\" class=\"settings-section\" data-name=\"your-bots\">\n    <div class=\"bot-settings-form\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"current_user"),"is_guest"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "\n        <hr/>\n\n        <div class=\"tab-container\"></div>\n\n        <div id=\"active_bots_list_container\" class=\"bots_section\" data-bot-settings-section=\"active-bots\">\n            <div class=\"config-download-text\">\n                <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download config of all active outgoing webhook bots in Zulip Botserver format.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":22},"end":{"line":30,"column":109}}}))
    + "</span>\n                <a type=\"submit\" download=\""
    + alias2(container.lambda(container.strict(depth0, "botserverrc", {"start":{"line":31,"column":45},"end":{"line":31,"column":56}} ), depth0))
    + "\" id= \"download_botserverrc\" class=\"bootstrap-btn tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download botserverrc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":157},"end":{"line":31,"column":186}}}))
    + "\">\n                    <i class=\"fa fa-download sea-green\" aria-hidden=\"true\"></i>\n                </a>\n            </div>\n            <ol id=\"active_bots_list\" class=\"bots_list\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have no active bots.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":68},"end":{"line":35,"column":101}}}))
    + "\">\n            </ol>\n        </div>\n\n        <div id=\"inactive_bots_list_container\" class=\"bots_section\" data-bot-settings-section=\"inactive-bots\">\n            <ol id=\"inactive_bots_list\" class=\"bots_list\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have no inactive bots.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":70},"end":{"line":40,"column":105}}}))
    + "\">\n            </ol>\n        </div>\n    </div>\n</div>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-integrations"],"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":147}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-api"],"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":129}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});