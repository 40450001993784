var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"message-header-stream-name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "stream_name", {"start":{"line":10,"column":23},"end":{"line":10,"column":34}} ), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                &nbsp;\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[0][0],{"name":"each","hash":{},"fn":container.program(6, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":25,"column":20},"end":{"line":28,"column":29}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.strict, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"radio\" id=\"color-"
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" class=\"color-swatch-input\" name=\"color-picker-select\" data-swatch-color=\""
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,blockParams[0][0],lookupProperty(depths[1],"stream_color"),{"name":"eq","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":26,"column":163},"end":{"line":26,"column":194}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":26,"column":157},"end":{"line":26,"column":210}}})) != null ? stack1 : "")
    + " />\n                        <label role=\"menuitemradio\" class=\"color-swatch-label tippy-zulip-delayed-tooltip\" for=\"color-"
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" style=\"background-color: "
    + alias2(alias1(blockParams[0][0], depth0))
    + ";\" aria-label=\""
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" data-tippy-content=\""
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" data-swatch-color=\""
    + alias2(alias1(blockParams[0][0], depth0))
    + "\" data-row=\""
    + alias2(alias1(alias4(container.data(data, 1), "index", {"start":{"line":27,"column":282},"end":{"line":27,"column":291}} ), depth0))
    + "\" data-column=\""
    + alias2(alias1(alias4(data, "index", {"start":{"line":27,"column":310},"end":{"line":27,"column":316}} ), depth0))
    + "\" tabindex=\"0\"></label>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu color-picker-popover no-auto-hide-left-sidebar-overlay\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"message_header message_header_stream\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":2,"column":72},"end":{"line":2,"column":81}} ), depth0))
    + "\">\n        <div class=\"message-header-contents\" style=\"background: "
    + alias3(alias2(alias1(depth0, "recipient_bar_color", {"start":{"line":3,"column":66},"end":{"line":3,"column":85}} ), depth0))
    + ";\">\n            <div class=\"message_label_clickable stream_label\">\n                <span class=\"stream-privacy-modified-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":5,"column":61},"end":{"line":5,"column":70}} ), depth0))
    + " stream-privacy filter-icon\"  style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_privacy_icon_color", {"start":{"line":5,"column":118},"end":{"line":5,"column":143}} ), depth0))
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("../stream_privacy.hbs"),depth0,{"name":"../stream_privacy","data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"stream_name"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"blockParams":blockParams,"loc":{"start":{"line":8,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <button class=\"color_picker_confirm_button icon-button icon-button-square icon-button-neutral tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"color-picker-confirm-button-tooltip-template\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Confirm new color",{"name":"t","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":219},"end":{"line":16,"column":245}}}))
    + "\" tabindex=\"0\">\n                <i class=\"zulip-icon zulip-icon-check\"></i>\n            </button>\n        </div>\n    </div>\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\">\n            <div role=\"group\" class=\"color-swatch-list\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Stream color",{"name":"t","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":23,"column":68},"end":{"line":23,"column":89}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"stream_color_palette"),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":16},"end":{"line":29,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <label role=\"menuitem\" class=\"custom-color-picker popover-menu-link\" tabindex=\"0\">\n                <i class=\"custom-color-swatch-icon popover-menu-icon\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Custom color",{"name":"t","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":36,"column":49},"end":{"line":36,"column":69}}}))
    + "</span>\n                <input type=\"color\" class=\"color-picker-input\" tabindex=\"-1\" value=\""
    + alias3(alias2(alias1(depth0, "stream_color", {"start":{"line":37,"column":86},"end":{"line":37,"column":98}} ), depth0))
    + "\" />\n            </label>\n        </li>\n    </ul>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true,"useBlockParams":true});