var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"domain\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "domain", {"start":{"line":3,"column":25},"end":{"line":3,"column":31}} ), depth0))
    + "</td>\n    <td>\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" class=\"allow-subdomains\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"allow_subdomains"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":64}}})) != null ? stack1 : "")
    + " />\n            <span class=\"rendered-checkbox\"></span>\n        </label>\n    </td>\n    <td>\n        "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"danger","attention":"quiet","custom_classes":"delete_realm_domain","label":lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":28}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"realm_domain"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});