var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"remove_subscription\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_private_stream_unsub_tooltip"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Use channel settings to unsubscribe from private channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":211},"end":{"line":13,"column":275}}}),"aria-label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":174},"end":{"line":13,"column":191}}}),"intent":"danger","attention":"quiet","custom_classes":"remove-subscription-button tippy-zulip-tooltip","label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":64}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_last_user_in_private_stream_unsub_tooltip"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Use channel settings to unsubscribe the last user from a private channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":211},"end":{"line":15,"column":290}}}),"aria-label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":174},"end":{"line":15,"column":191}}}),"intent":"danger","attention":"quiet","custom_classes":"remove-subscription-button tippy-zulip-tooltip","label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":64}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":154},"end":{"line":17,"column":171}}}),"intent":"danger","attention":"quiet","custom_classes":"remove-subscription-button","label":lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":64}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":1,"column":22},"end":{"line":1,"column":31}} ), depth0))
    + "\">\n    <td class=\"user-profile-channel-row\">\n        <div class=\"user-profile-channel-container\">\n            <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":4,"column":57},"end":{"line":4,"column":66}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_color", {"start":{"line":4,"column":113},"end":{"line":4,"column":125}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n            <a class=\"user-profile-channel-list-item\" href=\""
    + alias3(alias2(alias1(depth0, "stream_edit_url", {"start":{"line":7,"column":62},"end":{"line":7,"column":77}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":7,"column":83},"end":{"line":7,"column":87}} ), depth0))
    + "</a>\n        </div>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_unsubscribe_button"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});