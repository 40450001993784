var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"new_direct_message_button_container\">\n                <button type=\"button\" class=\"compose_new_direct_message_button\"\n                  id=\"new_direct_message_button\"\n                  data-tooltip-template-id=\"new_direct_message_button_tooltip_template\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":47}}}))
    + "\n                </button>\n            </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"compose-content\">\n    <div id=\"scroll-to-bottom-button-container\" aria-hidden=\"true\">\n        <div id=\"scroll-to-bottom-button-clickable-area\" data-tooltip-template-id=\"scroll-to-bottom-button-tooltip-template\">\n            <div id=\"scroll-to-bottom-button\">\n                <i class=\"scroll-to-bottom-icon fa fa-chevron-down\"></i>\n            </div>\n        </div>\n    </div>\n    <div id=\"compose_controls\">\n        <div id=\"compose_buttons\">\n            <div class=\"reply_button_container\">\n                <div class=\"compose-reply-button-wrapper\" data-reply-button-type=\"selected_message\">\n                    <button type=\"button\" class=\"compose_reply_button\"\n                      id=\"left_bar_compose_reply_button_big\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":48}}}))
    + "\n                    </button>\n                </div>\n                <button type=\"button\" class=\"compose_new_conversation_button\"\n                  id=\"new_conversation_button\"\n                  data-tooltip-template-id=\"new_stream_message_button_tooltip_template\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Start new conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":51}}}))
    + "\n                </button>\n            </div>\n            <span class=\"mobile_button_container\">\n                <button type=\"button\" class=\"compose_mobile_button\"\n                  id=\"left_bar_compose_mobile_button_big\"\n                  data-tooltip-template-id=\"left_bar_compose_mobile_button_tooltip_template\">\n                    +\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"embedded"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"message_comp\">\n        <div id=\"compose_banners\" data-simplebar data-simplebar-tab-index=\"-1\"></div>\n        <div class=\"composition-area\">\n            <form id=\"send_message_form\" action=\"/json/messages\" method=\"post\">\n                <div class=\"compose_table\">\n                    <div id=\"compose_top\">\n                        <div id=\"compose-recipient\">\n                            "
    + ((stack1 = container.invokePartial(require("./dropdown_widget_wrapper.hbs"),undefined,{"name":"dropdown_widget_wrapper","hash":{"widget_name":"compose_select_recipient"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            <div class=\"topic-marker-container\">\n                                <a role=\"button\" class=\"conversation-arrow zulip-icon zulip-icon-chevron-right\"></a>\n                            </div>\n                            <div id=\"compose_recipient_box\">\n                                <input type=\"text\" name=\"stream_message_recipient_topic\" id=\"stream_message_recipient_topic\" maxlength=\""
    + alias2(container.lambda(container.strict(depth0, "max_topic_length", {"start":{"line":59,"column":139},"end":{"line":59,"column":155}} ), depth0))
    + "\" value=\"\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":182},"end":{"line":59,"column":196}}}))
    + "\" autocomplete=\"off\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":242},"end":{"line":59,"column":256}}}))
    + "\" />\n                                <span id=\"topic-not-mandatory-placeholder\" class=\"placeholder\">\n                                    "
    + ((stack1 = container.invokePartial(require("./topic_not_mandatory_placeholder_text.hbs"),undefined,{"name":"topic_not_mandatory_placeholder_text","hash":{"empty_string_topic_display_name":lookupProperty(depth0,"empty_string_topic_display_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </span>\n                                <button type=\"button\" id=\"recipient_box_clear_topic_button\" class=\"tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":148},"end":{"line":63,"column":168}}}))
    + "\" tabindex=\"-1\">\n                                    <i class=\"zulip-icon zulip-icon-close\"></i>\n                                </button>\n                            </div>\n                            <div id=\"compose-direct-recipient\" data-before=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":76},"end":{"line":67,"column":92}}}))
    + "\">\n                                <div class=\"pill-container\">\n                                    <div class=\"input\" contenteditable=\"true\" id=\"private_message_recipient\" data-no-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add one or more users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":134},"end":{"line":69,"column":164}}}))
    + "\" data-some-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add another user...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":193},"end":{"line":69,"column":221}}}))
    + "\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"messagebox\">\n                        <div id=\"message-content-container\" class=\"surround-formatting-buttons-row\">\n                            <textarea class=\"new_message_textarea message-textarea\" name=\"content\" id='compose-textarea' placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":134},"end":{"line":76,"column":168}}}))
    + "\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":195},"end":{"line":76,"column":232}}}))
    + "\"></textarea>\n                            <div id=\"preview-message-area-container\">\n                                <div class=\"scrolling_list preview_message_area\" data-simplebar data-simplebar-tab-index=\"-1\" id=\"preview_message_area\" style=\"display:none;\">\n                                    <div class=\"markdown_preview_spinner\"></div>\n                                    <div class=\"preview_content rendered_markdown\"></div>\n                                </div>\n                            </div>\n                            <div class=\"composebox-buttons\">\n                                <button type=\"button\" class=\"maximize-composebox-button zulip-icon zulip-icon-maximize-diagonal\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Maximize compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":141},"end":{"line":84,"column":170}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Maximize compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":192},"end":{"line":84,"column":221}}}))
    + "\"></button>\n                                <button type=\"button\" class=\"expand-composebox-button zulip-icon zulip-icon-expand-diagonal\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":137},"end":{"line":85,"column":164}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":186},"end":{"line":85,"column":213}}}))
    + "\"></button>\n                                <button type=\"button\" class=\"collapse-composebox-button zulip-icon zulip-icon-collapse-diagonal\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":141},"end":{"line":86,"column":170}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose box",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":192},"end":{"line":86,"column":221}}}))
    + "\"></button>\n                            </div>\n                            <div class=\"drag\"></div>\n                        </div>\n\n                        <div id=\"message-send-controls-container\">\n                            <a id=\"compose-drafts-button\" role=\"button\" class=\"send-control-button hide-sm\" tabindex=0 href=\"#drafts\">\n                                <span class=\"compose-drafts-text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":66},"end":{"line":93,"column":81}}}))
    + "</span><span class=\"compose-drafts-count-container\">(<span class=\"compose-drafts-count\"></span>)</span>\n                            </a>\n                            <span id=\"compose-limit-indicator\" class=\"message-limit-indicator\"></span>\n                            <div class=\"message-send-controls\">\n                                <button type=\"submit\" id=\"compose-send-button\" class=\"send_message compose-submit-button compose-send-or-save-button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":162},"end":{"line":97,"column":175}}}))
    + "\">\n                                    <img class=\"loader\" alt=\"\" src=\"\" />\n                                    <i class=\"zulip-icon zulip-icon-send\"></i>\n                                </button>\n                                <button class=\"send-control-button send-related-action-button\" id=\"send_later\" tabindex=0 type=\"button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send options",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":156},"end":{"line":101,"column":177}}}))
    + "\">\n                                    <i class=\"zulip-icon zulip-icon-more-vertical\"></i>\n                                </button>\n                            </div>\n                        </div>\n\n                        <div id=\"message-formatting-controls-container\" class=\"compose-scrolling-buttons-container\">\n                            "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            <button type=\"button\" class=\"formatting-control-scroller-button formatting-scroller-forward\">\n                                <i class=\"scroller-forward-icon zulip-icon zulip-icon-compose-scroll-right\"></i>\n                            </button>\n                            <button type=\"button\" class=\"formatting-control-scroller-button formatting-scroller-backward\">\n                                <i class=\"scroller-backward-icon zulip-icon zulip-icon-compose-scroll-left\"></i>\n                            </button>\n                        </div>\n                    </div>\n                </div>\n            </form>\n        </div>\n        <button type=\"button\" class=\"close zulip-icon zulip-icon-close\" id='compose_close' data-tooltip-template-id=\"compose_close_tooltip_template\"></button>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});