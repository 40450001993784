var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"stream_archived"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":33,"column":23}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n            <li role=\"none\" class=\"popover-menu-list-item\">\n                <div role=\"group\" class=\"tab-picker popover-menu-tab-group\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic visibility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":88},"end":{"line":11,"column":113}}}))
    + "\">\n                    <input type=\"radio\" id=\"sidebar-topic-muted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":12,"column":156},"end":{"line":12,"column":185}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":12,"column":195},"end":{"line":12,"column":247}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":189},"end":{"line":12,"column":263}}})) != null ? stack1 : "")
    + " />\n                    <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-muted-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":148},"end":{"line":13,"column":161}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":183},"end":{"line":13,"column":196}}}))
    + "\" tabindex=\"0\">\n                        <i class=\"zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                    </label>\n                    <input type=\"radio\" id=\"sidebar-topic-inherit-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":16,"column":158},"end":{"line":16,"column":189}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":16,"column":199},"end":{"line":16,"column":253}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":193},"end":{"line":16,"column":269}}})) != null ? stack1 : "")
    + " />\n                    <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-inherit-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":150},"end":{"line":17,"column":166}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":188},"end":{"line":17,"column":204}}}))
    + "\" tabindex=\"0\">\n                        <i class=\"zulip-icon zulip-icon-inherit\" aria-hidden=\"true\"></i>\n                    </label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":57}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "                    <input type=\"radio\" id=\"sidebar-topic-followed-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":26,"column":159},"end":{"line":26,"column":191}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":26,"column":201},"end":{"line":26,"column":256}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":195},"end":{"line":26,"column":272}}})) != null ? stack1 : "")
    + " />\n                    <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-followed-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":151},"end":{"line":27,"column":166}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":188},"end":{"line":27,"column":203}}}))
    + "\" tabindex=\"0\">\n                        <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n                    </label>\n                    <span class=\"slider\"></span>\n                </div>\n            </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"radio\" id=\"sidebar-topic-unmuted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":21,"column":158},"end":{"line":21,"column":189}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":199},"end":{"line":21,"column":253}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":193},"end":{"line":21,"column":269}}})) != null ? stack1 : "")
    + " />\n                    <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-unmuted-policy\" aria-label=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":150},"end":{"line":22,"column":165}}}))
    + "\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":187},"end":{"line":22,"column":202}}}))
    + "\" tabindex=\"0\">\n                        <i class=\"zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                    </label>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n            <li role=\"none\" class=\"popover-menu-list-item text-item italic\">\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"There are no messages in this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":49},"end":{"line":38,"column":94}}}))
    + "</span>\n            </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"show_ai_features"),lookupProperty(depth0,"can_summarize_topics"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":43,"column":61}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_starred_messages"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":58,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_unread_messages"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-copy-link-to-topic popover-menu-link\" data-clipboard-text=\""
    + alias2(container.lambda(container.strict(depth0, "url", {"start":{"line":75,"column":120},"end":{"line":75,"column":123}} ), depth0))
    + "\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link to topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":53},"end":{"line":77,"column":80}}}))
    + "</span>\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"can_move_topic"),lookupProperty(depth0,"can_rename_topic"),lookupProperty(depth0,"is_realm_admin"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":81,"column":18},"end":{"line":81,"column":69}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":85,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_move_topic"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":100,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"can_resolve_topic"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_empty_string_topic"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":101,"column":41},"end":{"line":101,"column":68}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":101,"column":18},"end":{"line":101,"column":69}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":12},"end":{"line":113,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_realm_admin"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"stream_archived"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":114,"column":38},"end":{"line":114,"column":59}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":114,"column":18},"end":{"line":114,"column":60}}}),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":12},"end":{"line":121,"column":19}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-summarize-topic popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon fa fa-magic\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Summarize recent messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":53},"end":{"line":47,"column":86}}}))
    + "</span>\n                </a>\n            </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-unstar-all-in-topic popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-star\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unstar all messages in topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":53},"end":{"line":55,"column":90}}}))
    + "</span>\n                </a>\n            </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-read popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-read\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":53},"end":{"line":63,"column":87}}}))
    + "</span>\n                </a>\n            </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-unread popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":53},"end":{"line":70,"column":89}}}))
    + "</span>\n                </a>\n            </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"stream_archived"),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":84,"column":27}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "                <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-move-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Move topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":53},"end":{"line":90,"column":72}}}))
    + "</span>\n                </a>\n            </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":100,"column":12}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-rename-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-rename\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Rename topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":53},"end":{"line":97,"column":74}}}))
    + "</span>\n                </a>\n            </li>\n            ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-toggle-resolved popover-menu-link\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"topic_is_resolved"),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":104,"column":20},"end":{"line":110,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"popover-menu-icon zulip-icon zulip-icon-check-x\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":53},"end":{"line":106,"column":79}}}))
    + "</span>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"popover-menu-icon zulip-icon zulip-icon-check\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":53},"end":{"line":109,"column":77}}}))
    + "</span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-delete-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":53},"end":{"line":118,"column":73}}}))
    + "</span>\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu no-auto-hide-left-sidebar-overlay\" id=\"topic-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-topic-header text-item popover-menu-list-item\">\n            <span class=\"popover-topic-name "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":99}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "topic_display_name", {"start":{"line":4,"column":103},"end":{"line":4,"column":121}} ), depth0))
    + "</span>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_topic_empty"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":122,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});