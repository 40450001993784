var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"emoji-settings\" data-name=\"emoji-settings\" class=\"settings-section\">\n    <div class=\"emoji-settings-tip-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":78}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"tip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You do not have permission to add custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":80}}}))
    + "</div>\n    </div>\n    <p class=\"add-emoji-text "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":70}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add extra emoji for members of the {realm_name} organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":78}}}))
    + "\n    </p>\n    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"hidden":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":33}}}),"label":lookupProperty(helpers,"t").call(alias1,"Add a new emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":33}}}),"intent":"brand","attention":"quiet","id":"add-custom-emoji-button"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":32}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":66},"end":{"line":18,"column":84}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":54}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table admin_emoji_table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":80},"end":{"line":23,"column":93}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"image\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":48}}}))
    + "</th>\n                <th class=\"image\" data-sort=\"author_full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Author",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":63},"end":{"line":27,"column":78}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_emoji_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":54},"end":{"line":32,"column":89}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No custom emojis match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":118},"end":{"line":32,"column":170}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});