var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"button-group\">\n        <div class=\"sub_unsub_button_wrapper inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"should_display_subscription_button"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":140}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\"cannot-subscribe-tooltip-template\">\n            <template id=\"cannot-subscribe-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n            <button class=\"button small rounded subscribe-button sub_unsub_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_subscription_button"),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":82},"end":{"line":14,"column":158}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"subscribed"),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":159},"end":{"line":14,"column":205}}})) != null ? stack1 : "")
    + "\" type=\"button\" name=\"button\"  data-tooltip-template-id=\"toggle-subscription-tooltip-template\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"should_display_subscription_button"),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":300},"end":{"line":14,"column":376}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"subscribed"),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n        </div>\n        <a href=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "preview_url", {"start":{"line":22,"column":19},"end":{"line":22,"column":30}} ), depth0))
    + "\" class=\"button small rounded tippy-zulip-delayed-tooltip\" id=\"preview-stream-button\" role=\"button\" data-tooltip-template-id=\"view-stream-tooltip-template\" data-tippy-placement=\"bottom\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"should_display_preview_button"),{"name":"unless","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":218},"end":{"line":22,"column":292}}})) != null ? stack1 : "")
    + "><i class=\"fa fa-eye\"></i></a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_archive_stream"),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "cannot-subscribe-tooltip";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Cannot subscribe to private channel <z-stream></z-stream>\n                        \n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../inline_decorated_stream_name.hbs"),undefined,{"name":"../inline_decorated_stream_name","hash":{"stream":lookupProperty(depths[1],"sub")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "toggle-subscription-tooltip";
},"9":function(container,depth0,helpers,partials,data) {
    return "unsubscribed";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":40}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Subscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":38}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button small rounded button-danger deactivate tippy-zulip-delayed-tooltip\" type=\"button\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Archive channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":132},"end":{"line":24,"column":155}}}))
    + "\">\n            <span class=\"icon-container\">\n                <i class=\"zulip-icon zulip-icon-archive\" aria-hidden=\"true\"></i>\n            </span>\n        </button>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-settings-tip-container\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_settings_tip.hbs"),depth0,{"name":"stream_settings_tip","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"stream-header\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy_icon.hbs"),undefined,{"name":"stream_privacy_icon","hash":{"is_archived":lookupProperty(depth0,"is_archived"),"is_web_public":lookupProperty(depth0,"is_web_public"),"invite_only":lookupProperty(depth0,"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"stream-name\">\n                    <span class=\"sub-stream-name\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":47,"column":72},"end":{"line":47,"column":76}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":47,"column":82},"end":{"line":47,"column":86}} ), depth0))
    + "</span>\n                </div>\n                <div class=\"stream_change_property_info alert-notification\"></div>\n                <div class=\"button-group\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"can_change_name_description"),{"name":"unless","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":42},"end":{"line":50,"column":112}}})) != null ? stack1 : "")
    + ">\n                    <button id=\"open_stream_info_modal\" class=\"button rounded small button-warning tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Edit channel name and description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":148},"end":{"line":51,"column":190}}}))
    + "\">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"stream-description\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_description.hbs"),undefined,{"name":"stream_description","hash":{"rendered_description":lookupProperty(depth0,"rendered_description")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            "
    + ((stack1 = container.invokePartial(require("./stream_types.hbs"),depth0,{"name":"stream_types","hash":{"group_setting_labels":lookupProperty(depths[1],"group_setting_labels"),"prefix":"id_","is_stream_edit":true,"org_level_message_retention_setting":lookupProperty(depths[1],"org_level_message_retention_setting"),"has_billing_access":lookupProperty(depths[1],"has_billing_access"),"is_business_type_org":lookupProperty(depths[1],"is_business_type_org"),"upgrade_text_for_wide_organization_logo":lookupProperty(depths[1],"upgrade_text_for_wide_organization_logo"),"zulip_plan_is_not_limited":lookupProperty(depths[1],"zulip_plan_is_not_limited"),"check_default_stream":lookupProperty(depths[1],"check_default_stream"),"stream_privacy_policy":lookupProperty(depths[1],"stream_privacy_policy"),"stream_privacy_policy_values":lookupProperty(depths[1],"stream_privacy_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"creator_details stream_details_subsection\">\n                    "
    + ((stack1 = container.invokePartial(require("../creator_details.hbs"),depth0,{"name":"../creator_details","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"stream_details_subsection\">\n                    "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Channel ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":87,"column":38}}}))
    + "<br/>\n                    "
    + alias1(container.lambda(container.strict(depth0, "stream_id", {"start":{"line":88,"column":22},"end":{"line":88,"column":31}} ), depth0))
    + "\n                </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group\">\n                        "
    + ((stack1 = container.invokePartial(require("./stream_settings_checkbox.hbs"),undefined,{"name":"stream_settings_checkbox","hash":{"label":lookupProperty(depth0,"label"),"is_disabled":lookupProperty(depth0,"is_disabled"),"disabled_realm_setting":lookupProperty(depth0,"disabled_realm_setting"),"notification_setting":false,"stream_id":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"sub"),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":118,"column":36},"end":{"line":118,"column":63}}}),"is_muted":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"sub"),"is_muted",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":117,"column":35},"end":{"line":117,"column":61}}}),"is_checked":lookupProperty(depth0,"is_checked"),"setting_name":lookupProperty(depth0,"name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group\">\n                        "
    + ((stack1 = container.invokePartial(require("./stream_settings_checkbox.hbs"),undefined,{"name":"stream_settings_checkbox","hash":{"label":lookupProperty(depth0,"label"),"is_disabled":lookupProperty(depth0,"is_disabled"),"disabled_realm_setting":lookupProperty(depth0,"disabled_realm_setting"),"notification_setting":true,"stream_id":lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depths[1],"sub"),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":147,"column":36},"end":{"line":147,"column":63}}}),"is_checked":lookupProperty(depth0,"is_checked"),"setting_name":lookupProperty(depth0,"name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"edit_subscribers_for_stream\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_members.hbs"),depth0,{"name":"stream_members","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stream_settings_header\" data-stream-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"sub"), "stream_id", {"start":{"line":1,"column":54},"end":{"line":1,"column":67}} ), depth0))
    + "\">\n    <div class=\"tab-container\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias4,lookupProperty(depth0,"sub"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":31,"column":13}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"subscription_settings\" data-stream-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"sub"), "stream_id", {"start":{"line":33,"column":53},"end":{"line":33,"column":66}} ), depth0))
    + "\">\n    <div class=\"inner-box\">\n        <div class=\"stream-creation-confirmation-banner\"></div>\n        <div class=\"stream_section\" data-stream-section=\"general\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias4,lookupProperty(depth0,"sub"),{"name":"with","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":74,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"stream_details_box\">\n                <div class=\"stream_details_box_header\">\n                    <h3 class=\"stream_setting_subsection_title\">\n                        "
    + alias3(lookupProperty(helpers,"t").call(alias4,"Channel details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":48}}}))
    + "\n                    </h3>\n                    <div class=\"stream_email_address_error alert-notification\"></div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias4,lookupProperty(depth0,"sub"),{"name":"with","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":90,"column":25}}})) != null ? stack1 : "")
    + "                <div class=\"input-group stream-email-box\">\n                    <label for=\"copy_stream_email_button\" class=\"title inline-block\">Email address</label>\n                    <p class=\"field-hint\">\n                        "
    + alias3(lookupProperty(helpers,"t").call(alias4,"You can use email to send messages to Zulip channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":24},"end":{"line":94,"column":85}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/message-a-channel-by-email"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </p>\n                    <span class=\"generate-channel-email-button-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"can_access_stream_email"),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":73},"end":{"line":97,"column":143}}})) != null ? stack1 : "")
    + "\">\n                        <button class=\"button small rounded copy_email_button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"can_access_stream_email"),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":79},"end":{"line":98,"column":144}}})) != null ? stack1 : "")
    + " id=\"copy_stream_email_button\" type=\"button\">\n                            <span class=\"copy_button\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Generate email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":54},"end":{"line":99,"column":85}}}))
    + "</span>\n                        </button>\n                    </span>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"personal-stream-settings\" class=\"stream_section\" data-stream-section=\"personal\">\n            <div class=\"subsection-parent\">\n                <div class=\"subsection-header\">\n                    <h3 class=\"stream_setting_subsection_title inline-block\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Personal settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":77},"end":{"line":109,"column":103}}}))
    + "</h3>\n                    <div class=\"stream_change_property_status alert-notification\"></div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"other_settings"),{"name":"each","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":124,"column":25}}})) != null ? stack1 : "")
    + "                <div class=\"input-group\">\n                    <label class=\"settings-field-label channel-color-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Channel color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":76},"end":{"line":126,"column":98}}}))
    + "</label>\n                    <button class=\"button small rounded stream-settings-color-selector choose_stream_color\" data-stream-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"sub"), "stream_id", {"start":{"line":127,"column":127},"end":{"line":127,"column":140}} ), depth0))
    + "\">\n                        <span class=\"stream-settings-color-preview\" style=\"background: "
    + alias3(alias2(alias1(lookupProperty(depth0,"sub"), "color", {"start":{"line":128,"column":89},"end":{"line":128,"column":98}} ), depth0))
    + ";\"></span>\n                        <span class=\"stream-settings-color-selector-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Change color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":75},"end":{"line":129,"column":95}}}))
    + "</span>\n                    </button>\n                </div>\n            </div>\n            <div class=\"subsection-parent\">\n                <div class=\"subsection-header\">\n                    <h4 class=\"stream_setting_subsection_title\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Notification settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":64},"end":{"line":135,"column":94}}}))
    + "</h4>\n                    <div class=\"stream_change_property_status alert-notification\"></div>\n                </div>\n                <p>"
    + alias3(lookupProperty(helpers,"t").call(alias4,"In muted channels, channel notification settings apply only to unmuted topics.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":19},"end":{"line":138,"column":106}}}))
    + "</p>\n                <div class=\"input-group\">\n                    <button class=\"button small rounded reset-stream-notifications-button\" type=\"button\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Reset to default notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":140,"column":105},"end":{"line":140,"column":144}}}))
    + "</button>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"notification_settings"),{"name":"each","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":16},"end":{"line":153,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"stream_section\" data-stream-section=\"subscribers\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias4,lookupProperty(depth0,"sub"),{"name":"with","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":12},"end":{"line":162,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"4_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream"],"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":109}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});