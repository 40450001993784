var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <z-link>Learn more</z-link> about other data export options.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/export-your-organization\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"alert\" id=\"export_status\" role=\"alert\">\n        <span class=\"export_status_text\"></span>\n    </div>\n    <form>\n        "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"type":"submit","intent":"brand","attention":"quiet","id":"start-export-button","label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Start export",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":34}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </form>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"data-exports\" class=\"settings-section\" data-name=\"data-exports-admin\">\n    <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Export organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":36}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/export-your-organization"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </h3>\n    <p>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your organization’s data will be exported in a format designed for imports into Zulip Cloud or a self-hosted installation of Zulip.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":148}}}))
    + "\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You will be able to export all public data, and (optionally) private data from users who have given their permission.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":134}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n    <p>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Depending on the size of your organization, an export can take anywhere from seconds to an hour.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":113}}}))
    + "\n    </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + "\n    <hr/>\n\n    <div class=\"tab-container\"></div>\n\n    <div class=\"export_section\" data-export-section=\"data-exports\">\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Data exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":36}}}))
    + "</h3>\n            <input type=\"hidden\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":61},"end":{"line":39,"column":84}}}))
    + "\"\n              aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":40,"column":49}}}))
    + "\"/>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <table class=\"table table-striped wrapped-table admin_exports_table\">\n                <thead class=\"table-sticky-headers\">\n                    <th class=\"active\" data-sort=\"user\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Requesting user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":56},"end":{"line":46,"column":80}}}))
    + "\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":49,"column":36}}}))
    + "</th>\n                    <th data-sort=\"numeric\" data-sort-prop=\"export_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":73},"end":{"line":50,"column":86}}}))
    + "\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":53,"column":39}}}))
    + "</th>\n                    <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":40},"end":{"line":54,"column":56}}}))
    + "</th>\n                </thead>\n                <tbody id=\"admin_exports_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no exports.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":60},"end":{"line":56,"column":90}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n\n    <div class=\"export_section\" data-export-section=\"export-permissions\">\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Export permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":63,"column":42}}}))
    + "</h3>\n            <div class=\"user_filters\">\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":"filter_by_consent"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":74},"end":{"line":66,"column":92}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":50},"end":{"line":66,"column":62}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <table class=\"table table-striped wrapped-table\">\n                <thead class=\"table-sticky-headers\">\n                    <th class=\"active\" data-sort=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":61},"end":{"line":73,"column":74}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Export permission",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":24},"end":{"line":74,"column":49}}}))
    + "</th>\n                </thead>\n                <tbody id=\"admin_export_consents_table\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":151}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});