var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"alert-word-item\" data-word='"
    + alias3(alias2(alias1(depth0, "word", {"start":{"line":3,"column":41},"end":{"line":3,"column":45}} ), depth0))
    + "'>\n    <td>\n        <div class=\"alert_word_listing\">\n            <span class=\"value\">"
    + alias3(alias2(alias1(depth0, "word", {"start":{"line":6,"column":34},"end":{"line":6,"column":38}} ), depth0))
    + "</span>\n        </div>\n    </td>\n    <td>\n        <button type=\"submit\" class=\"button rounded small delete button-danger remove-alert-word tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":146},"end":{"line":10,"column":161}}}))
    + "\" data-word=\""
    + alias3(alias2(alias1(depth0, "word", {"start":{"line":10,"column":176},"end":{"line":10,"column":180}} ), depth0))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"alert_word"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":15,"column":9}}})) != null ? stack1 : "");
},"useData":true});