var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <span class=\"status-circle zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":8,"column":67},"end":{"line":8,"column":84}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":8,"column":89},"end":{"line":8,"column":106}} ), depth0))
    + " user-circle hidden-for-spectators\" data-tippy-placement=\"bottom\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "user_last_seen_time_status", {"start":{"line":8,"column":196},"end":{"line":8,"column":222}} ), depth0))
    + "\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"text-item popover-menu-list-item\">\n                    <span class=\"personal-menu-status-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":30,"column":31}}})) != null ? stack1 : "")
    + "                        <span class=\"status_text personal-menu-status-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_placeholder_for_status_text"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":36,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </span>\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"personal-menu-clear-status popover-menu-link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":117},"end":{"line":39,"column":137}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":159},"end":{"line":39,"column":185}}}))
    + "\">\n                        <i class=\"personal-menu-clear-status-icon popover-menu-icon zulip-icon zulip-icon-x-circle\" aria-hidden=\"true\"></i>\n                    </a>\n                </li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":57},"end":{"line":47,"column":77}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":29,"column":35}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":24,"column":66},"end":{"line":24,"column":94}} ), depth0))
    + ":</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":29,"column":28}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img src=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":26,"column":40},"end":{"line":26,"column":61}} ), depth0))
    + "\" class=\"emoji status_emoji\" />\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji status_emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":28,"column":68},"end":{"line":28,"column":96}} ), depth0))
    + "\"></span>\n                            ";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"personal-menu-no-status-text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"No status text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":72},"end":{"line":33,"column":94}}}))
    + "</i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":35,"column":34},"end":{"line":35,"column":45}} ), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item hidden-for-spectators popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":57},"end":{"line":54,"column":76}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item hidden-for-spectators popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_off popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-play-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Turn off invisible mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":57},"end":{"line":62,"column":89}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item hidden-for-spectators popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_on popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-stop-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go invisible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":57},"end":{"line":69,"column":78}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"personal-menu-dropdown\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <nav class=\"personal-menu-nav\">\n        <header class=\"personal-menu-header\">\n            <div class=\"avatar\">\n                <img class=\"avatar-image"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4(alias3(alias2(depth0, "user_avatar", {"start":{"line":5,"column":90},"end":{"line":5,"column":101}} ), depth0))
    + "\"/>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"text-area\">\n                <p class=\"full-name\">"
    + alias4(alias3(alias2(depth0, "user_full_name", {"start":{"line":13,"column":39},"end":{"line":13,"column":53}} ), depth0))
    + "</p>\n                <p class=\"user-type\">"
    + alias4(alias3(alias2(depth0, "user_type", {"start":{"line":14,"column":39},"end":{"line":14,"column":48}} ), depth0))
    + "</p>\n            </div>\n        </header>\n        <section class=\"dropdown-menu-list-section personal-menu-actions\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":17,"column":90},"end":{"line":17,"column":97}} ), depth0))
    + "\">\n            <ul role=\"menu\" class=\"popover-menu-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":72,"column":23}}})) != null ? stack1 : "")
    + "                <li role=\"separator\" class=\"popover-menu-separator\"></li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" href=\"#user/"
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":76,"column":53},"end":{"line":76,"column":60}} ), depth0))
    + "\" tabindex=\"0\" class=\"view_full_user_profile popover-menu-link navigate-link-on-enter\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-account\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":57},"end":{"line":78,"column":83}}}))
    + "</span>\n                    </a>\n                </li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"narrow-self-direct-message popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-users\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages with yourself",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":57},"end":{"line":84,"column":93}}}))
    + "</span>\n                    </a>\n                </li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"narrow-messages-sent popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-message-square\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":57},"end":{"line":90,"column":84}}}))
    + "</span>\n                    </a>\n                </li>\n                <li role=\"separator\" class=\"popover-menu-separator\"></li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" href=\"#settings/profile\" class=\"open-profile-settings popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-tool\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":57},"end":{"line":98,"column":74}}}))
    + "</span>\n                    </a>\n                </li>\n                <li role=\"none\" class=\"popover-menu-list-item\">\n                    <div role=\"group\" class=\"tab-picker popover-menu-tab-group\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"App theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":92},"end":{"line":102,"column":110}}}))
    + "\">\n                        <input type=\"radio\" id=\"select-automatic-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias3(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"), "code", {"start":{"line":103,"column":130},"end":{"line":103,"column":164}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":103,"column":174},"end":{"line":103,"column":231}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":168},"end":{"line":103,"column":247}}})) != null ? stack1 : "")
    + " />\n                        <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-automatic-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":148},"end":{"line":104,"column":179}}}))
    + "\" data-tooltip-template-id=\"automatic-theme-template\" tabindex=\"0\">\n                            <i class=\"zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i>\n                        </label>\n                        <input type=\"radio\" id=\"select-light-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias3(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"light"), "code", {"start":{"line":107,"column":126},"end":{"line":107,"column":156}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"light"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":107,"column":166},"end":{"line":107,"column":219}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":160},"end":{"line":107,"column":235}}})) != null ? stack1 : "")
    + " />\n                        <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-light-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":144},"end":{"line":108,"column":171}}}))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":193},"end":{"line":108,"column":213}}}))
    + "\" tabindex=\"0\">\n                            <i class=\"zulip-icon zulip-icon-sun\" aria-hidden=\"true\"></i>\n                        </label>\n                        <input type=\"radio\" id=\"select-dark-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias3(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"dark"), "code", {"start":{"line":111,"column":125},"end":{"line":111,"column":154}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"dark"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":111,"column":164},"end":{"line":111,"column":216}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":158},"end":{"line":111,"column":232}}})) != null ? stack1 : "")
    + " />\n                        <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-dark-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":143},"end":{"line":112,"column":169}}}))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":191},"end":{"line":112,"column":210}}}))
    + "\" tabindex=\"0\">\n                            <i class=\"zulip-icon zulip-icon-moon\" aria-hidden=\"true\"></i>\n                        </label>\n                        <span class=\"slider\"></span>\n                    </div>\n                </li>\n                <li role=\"none\" class=\"popover-menu-list-item\">\n                    <div class=\"info-density-controls\">\n                        "
    + ((stack1 = container.invokePartial(require("../../settings/info_density_control_button_group.hbs"),undefined,{"name":"../../settings/info_density_control_button_group","hash":{"prefix":"personal_menu_","for_settings_ui":false,"property_value":lookupProperty(depth0,"web_font_size_px"),"default_icon_class":"zulip-icon-type-big","property":"web_font_size_px"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        "
    + ((stack1 = container.invokePartial(require("../../settings/info_density_control_button_group.hbs"),undefined,{"name":"../../settings/info_density_control_button_group","hash":{"prefix":"personal_menu_","for_settings_ui":false,"property_value":lookupProperty(depth0,"web_line_height_percent"),"default_icon_class":"zulip-icon-line-height-big","property":"web_line_height_percent"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </li>\n                <li role=\"separator\" class=\"popover-menu-separator\"></li>\n                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" class=\"logout_button hidden-for-spectators popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-log-out\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Log out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":57},"end":{"line":141,"column":73}}}))
    + "</span>\n                    </a>\n                </li>\n            </ul>\n        </section>\n    </nav>\n</div>\n";
},"usePartial":true,"useData":true});