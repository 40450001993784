var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"add-poll-form\">\n    <label class=\"poll-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Question",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":46}}}))
    + "</label>\n    <div class=\"poll-question-input-container\">\n        <input type=\"text\" id=\"poll-question-input\" autocomplete=\"off\" class=\"modal_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your question",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":109},"end":{"line":4,"column":130}}}))
    + "\" />\n    </div>\n    <label class=\"poll-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Options",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":45}}}))
    + "</label>\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Anyone can add more options after the poll is posted.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":7},"end":{"line":7,"column":68}}}))
    + "</p>\n    <ul class=\"poll-options-list\" data-simplebar data-simplebar-tab-index=\"-1\">\n        "
    + ((stack1 = container.invokePartial(require("./poll_modal_option.hbs"),undefined,{"name":"poll_modal_option","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./poll_modal_option.hbs"),undefined,{"name":"poll_modal_option","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./poll_modal_option.hbs"),undefined,{"name":"poll_modal_option","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </ul>\n</form>\n";
},"usePartial":true,"useData":true});