var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            You'll see a list of <b>recent conversations</b>, where each conversation is\n            labeled with a topic by the person who started it. Click on a conversation to\n            view it. You can always get back to recent conversations from the left sidebar.\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Click the name of a channel in the left sidebar, and click on any topic underneath\n            to view one conversation at a time. You can explore discussions of changes to the\n            design of the Zulip app in <b>#design</b>, or see ongoing issue investigations in <b>#issues</b>.\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    If you have any questions, please post in the <b>#user questions</b> channel, and we'll be happy to help.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Explore how hundreds of community participants use Zulip to brainstorm ideas, discuss technical challenges, ask questions, and give feedback:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":154}}}))
    + "\n\n    <ul>\n        <li>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n        <li>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n    </ul>\n\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</p>\n";
},"useData":true});