var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"possible_send_later_today"),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <li>\n                    <a id=\""
    + alias3(alias2(alias1(data, "key", {"start":{"line":6,"column":29},"end":{"line":6,"column":33}} ), depth0))
    + "\" class=\"send_later_today send_later_option\" data-send-stamp=\""
    + alias3(alias2(alias1(depth0, "stamp", {"start":{"line":6,"column":99},"end":{"line":6,"column":109}} ), depth0))
    + "\" tabindex=\"0\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":6,"column":128},"end":{"line":6,"column":137}} ), depth0))
    + "</a>\n                </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <li>\n                <a id=\""
    + alias3(alias2(alias1(data, "key", {"start":{"line":12,"column":25},"end":{"line":12,"column":29}} ), depth0))
    + "\" class=\"send_later_tomorrow send_later_option\" data-send-stamp=\""
    + alias3(alias2(alias1(depth0, "stamp", {"start":{"line":12,"column":98},"end":{"line":12,"column":108}} ), depth0))
    + "\" tabindex=\"0\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":12,"column":127},"end":{"line":12,"column":136}} ), depth0))
    + "</a>\n            </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"possible_send_later_monday"),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":21}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <li>\n                    <a id=\""
    + alias3(alias2(alias1(data, "key", {"start":{"line":18,"column":29},"end":{"line":18,"column":33}} ), depth0))
    + "\" class=\"send_later_monday send_later_option\" data-send-stamp=\""
    + alias3(alias2(alias1(depth0, "stamp", {"start":{"line":18,"column":100},"end":{"line":18,"column":110}} ), depth0))
    + "\" tabindex=\"0\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":18,"column":129},"end":{"line":18,"column":138}} ), depth0))
    + "</a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"send_later_options\">\n    <ul class=\"send_later_list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"possible_send_later_today"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"send_later_tomorrow"),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"possible_send_later_monday"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "        <li>\n            <a class=\"send_later_custom send_later_option\" tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Custom time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":72},"end":{"line":23,"column":91}}}))
    + "</a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});