var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " active-user";
},"3":function(container,depth0,helpers,partials,data) {
    return " deactivated_user";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <td class=\"email settings-email-column\">\n        <span class=\"email\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "display_email", {"start":{"line":7,"column":30},"end":{"line":7,"column":43}} ), depth0))
    + "</span>\n    </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"email settings-email-column\">\n        <span class=\"hidden-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":11,"column":51}}}))
    + "</span>\n    </td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"owner panel_user_list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"no_owner"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n    <td class=\"bot_type\">\n        <span class=\"bot type\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "bot_type", {"start":{"line":28,"column":33},"end":{"line":28,"column":41}} ), depth0))
    + "</span>\n    </td>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "bot_owner_full_name", {"start":{"line":21,"column":14},"end":{"line":21,"column":33}} ), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),undefined,{"name":"../user_display_only_pill","hash":{"is_active":lookupProperty(depth0,"is_bot_owner_active"),"img_src":lookupProperty(depth0,"owner_img_src"),"user_id":lookupProperty(depth0,"bot_owner_id"),"display_value":lookupProperty(depth0,"bot_owner_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"display_last_active_column"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":38,"column":4}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"last_active\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"last_active_date"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    ";
},"16":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "last_active_date", {"start":{"line":33,"column":14},"end":{"line":33,"column":30}} ), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"loading-placeholder\"></div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <span class=\"user-status-settings\">\n            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":42,"column":48}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":18},"end":{"line":42,"column":84}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":42,"column":91},"end":{"line":42,"column":115}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":85},"end":{"line":42,"column":179}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small button-warning open-user-form tippy-zulip-delayed-tooltip manage-user-button\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":43,"column":150},"end":{"line":43,"column":252}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_id", {"start":{"line":43,"column":270},"end":{"line":43,"column":277}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_edit"),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":281},"end":{"line":43,"column":326}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "class=\"tippy-zulip-tooltip\"";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be edited.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":137},"end":{"line":42,"column":171}}}))
    + "\"";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"cannot_edit"),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":164},"end":{"line":43,"column":217}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":187},"end":{"line":43,"column":206}}}));
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":225},"end":{"line":43,"column":245}}}));
},"30":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_deactivate"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":18},"end":{"line":48,"column":77}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":48,"column":84},"end":{"line":48,"column":114}}}),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":48,"column":78},"end":{"line":48,"column":273}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small button-danger deactivate deactivate-user-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":49,"column":100},"end":{"line":49,"column":174}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_deactivate"),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":176},"end":{"line":49,"column":227}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"zulip-icon zulip-icon-user-x\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":136},"end":{"line":48,"column":175}}}))
    + "\"";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"cannot_deactivate"),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":176},"end":{"line":48,"column":266}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This user cannot be deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":225},"end":{"line":48,"column":265}}}))
    + "\"";
},"38":function(container,depth0,helpers,partials,data) {
    return "deactivate-bot-tooltip";
},"40":function(container,depth0,helpers,partials,data) {
    return "deactivate-user-tooltip";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"button rounded small button-warning reactivate reactivate-user-button "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":54,"column":97},"end":{"line":54,"column":171}}})) != null ? stack1 : "")
    + "\">\n                <i class=\"zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n            </button>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "reactivate-bot-tooltip";
},"45":function(container,depth0,helpers,partials,data) {
    return "reactivate-user-tooltip";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"user_row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":80}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":1,"column":98},"end":{"line":1,"column":105}} ), depth0))
    + "\">\n    <td class=\"user_name panel_user_list\">\n        "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),undefined,{"name":"../user_display_only_pill","hash":{"is_current_user":lookupProperty(depth0,"is_current_user"),"is_active":lookupProperty(depth0,"is_active"),"img_src":lookupProperty(depth0,"img_src"),"user_id":lookupProperty(depth0,"user_id"),"display_value":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"display_email"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"user_role\">"
    + alias4(alias3(alias2(depth0, "user_role_text", {"start":{"line":15,"column":34},"end":{"line":15,"column":48}} ), depth0))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_modify"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});