var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"poll-widget\">\n    <div class=\"poll-widget-header-area\">\n        <h4 class=\"poll-question-header\"></h4>\n        <i class=\"fa fa-pencil poll-edit-question\"></i>\n        <div class=\"poll-question-bar\">\n            <input type=\"text\" class=\"poll-question\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add question",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":86}}}))
    + "\" />\n            <button class=\"poll-question-remove\"><i class=\"fa fa-remove\"></i></button>\n            <button class=\"poll-question-check\"><i class=\"fa fa-check\"></i></button>\n        </div>\n    </div>\n    <div class=\"poll-please-wait\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"We are about to have a poll.  Please wait for the question.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":75}}}))
    + "\n    </div>\n    <ul class=\"poll-widget\">\n    </ul>\n    <div class=\"poll-option-bar\">\n        <input type=\"text\" class=\"poll-option\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New option",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":60},"end":{"line":17,"column":78}}}))
    + "\" />\n        <button class=\"poll-option\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add option",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":18,"column":55}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});