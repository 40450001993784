var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"input-group\" name=\""
    + alias3(alias2(alias1(depth0, "botname", {"start":{"line":1,"column":33},"end":{"line":1,"column":40}} ), depth0))
    + "\" id=\""
    + alias3(alias2(alias1(depth0, "botname", {"start":{"line":1,"column":50},"end":{"line":1,"column":57}} ), depth0))
    + "_"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":1,"column":62},"end":{"line":1,"column":65}} ), depth0))
    + "\">\n    <label for=\""
    + alias3(alias2(alias1(depth0, "botname", {"start":{"line":2,"column":18},"end":{"line":2,"column":25}} ), depth0))
    + "_"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":2,"column":30},"end":{"line":2,"column":33}} ), depth0))
    + "_input\" class=\"modal-field-label\">"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":2,"column":71},"end":{"line":2,"column":74}} ), depth0))
    + "</label>\n    <input type=\"text\" name=\""
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":3,"column":31},"end":{"line":3,"column":34}} ), depth0))
    + "\" id=\""
    + alias3(alias2(alias1(depth0, "botname", {"start":{"line":3,"column":44},"end":{"line":3,"column":51}} ), depth0))
    + "_"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":3,"column":56},"end":{"line":3,"column":59}} ), depth0))
    + "_input\" class=\"modal_text_input\"\n      maxlength=1000 placeholder=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":4,"column":36},"end":{"line":4,"column":41}} ), depth0))
    + "\" value=\"\" />\n</div>\n";
},"useData":true});