var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " strikethrough ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"pill-container display_only_group_pill\">\n    <a data-user-group-id=\""
    + alias3(alias2(alias1(depth0, "group_id", {"start":{"line":2,"column":29},"end":{"line":2,"column":37}} ), depth0))
    + "\" class=\"view_user_group pill\" tabindex=\"0\">\n        <i class=\"zulip-icon zulip-icon-user-group no-presence-circle\" aria-hidden=\"true\"></i>\n        <span class=\"pill-label "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"strikethrough"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":75}}})) != null ? stack1 : "")
    + "\" >\n            <span class=\"pill-value\">"
    + alias3(alias2(alias1(depth0, "display_value", {"start":{"line":5,"column":39},"end":{"line":5,"column":52}} ), depth0))
    + "</span>\n        </span>\n    </a>\n</span>\n";
},"useData":true});