var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"subscriber-email settings-email-column "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"soft_removed"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":58},"end":{"line":6,"column":100}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "email", {"start":{"line":6,"column":104},"end":{"line":6,"column":109}} ), depth0))
    + "</td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " strikethrough ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"hidden-subscriber-email "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"soft_removed"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":85}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":87},"end":{"line":8,"column":103}}}))
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":162},"end":{"line":12,"column":171}}}),"intent":"neutral","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":106},"end":{"line":12,"column":115}}}),"custom_classes":"undo_soft_removed_potential_subscriber"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":154},"end":{"line":14,"column":166}}}),"intent":"neutral","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":95},"end":{"line":14,"column":107}}}),"custom_classes":"remove_potential_subscriber"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"settings-subscriber-row\" data-user-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_id", {"start":{"line":1,"column":52},"end":{"line":1,"column":59}} ), depth0))
    + "\" >\n    <td class=\"panel_user_list\">\n        "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),depth0,{"name":"../user_display_only_pill","hash":{"is_active":true,"strikethrough":lookupProperty(depth0,"soft_removed"),"display_value":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"soft_removed"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"usePartial":true,"useData":true});