var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "(<i>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "label_parens_text", {"start":{"line":7,"column":39},"end":{"line":7,"column":56}} ), depth0))
    + "</i>)";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":lookupProperty(depth0,"help_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "prefix", {"start":{"line":12,"column":79},"end":{"line":12,"column":85}} ), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "id_";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\n    <label class=\"group-setting-label "
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":5,"column":40},"end":{"line":5,"column":52}} ), depth0))
    + "_label\">\n        "
    + alias3(alias2(alias1(depth0, "label", {"start":{"line":6,"column":10},"end":{"line":6,"column":15}} ), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"label_parens_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":70}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"help_link"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n    <div class=\"pill-container person_picker prop-element\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":12,"column":63},"end":{"line":12,"column":105}}})) != null ? stack1 : "")
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":12,"column":107},"end":{"line":12,"column":119}} ), depth0))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\" data-placeholder=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":68},"end":{"line":13,"column":103}}}))
    + "\"></div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});