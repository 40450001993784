var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"mark_stream_as_read popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-read\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":82}}}))
    + "</span>\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"mark_stream_as_unread popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":49},"end":{"line":23,"column":84}}}))
    + "</span>\n            </a>\n        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"stream-popover-go-to-channel-feed popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-all-messages\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go to channel feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-unpin\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unpin channel from top",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":49},"end":{"line":52,"column":79}}}))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-pin\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Pin channel to top",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":49},"end":{"line":55,"column":75}}}))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":49},"end":{"line":63,"column":71}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":49},"end":{"line":66,"column":69}}}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"popover_sub_unsub_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-circle-x\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":49},"end":{"line":74,"column":68}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"choose_stream_color popover-menu-link\" data-stream-id=\""
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":79,"column":96},"end":{"line":79,"column":112}} ), depth0))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-pipette\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Change color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":49},"end":{"line":81,"column":69}}}))
    + "</span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu no-auto-hide-left-sidebar-overlay\" id=\"stream-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\" data-stream-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":2,"column":65},"end":{"line":2,"column":81}} ), depth0))
    + "\" data-name=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "name", {"start":{"line":2,"column":100},"end":{"line":2,"column":111}} ), depth0))
    + "\">\n        <li role=\"none\" class=\"popover-stream-header text-item popover-menu-list-item\">\n            <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":4,"column":57},"end":{"line":4,"column":73}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "color", {"start":{"line":4,"column":120},"end":{"line":4,"column":132}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("../../stream_privacy.hbs"),undefined,{"name":"../../stream_privacy","hash":{"is_web_public":lookupProperty(lookupProperty(depth0,"stream"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"stream"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n            <span class=\"popover-stream-name\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "name", {"start":{"line":9,"column":48},"end":{"line":9,"column":59}} ), depth0))
    + "</span>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"has_unread_messages"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_go_to_channel_feed"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"copy_stream_link popover-menu-link\" data-clipboard-text=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "url", {"start":{"line":36,"column":98},"end":{"line":36,"column":108}} ), depth0))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy link to channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":49},"end":{"line":38,"column":78}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"open_stream_settings popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-gear\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Channel settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":74}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"pin_to_top popover-menu-link\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(lookupProperty(depth0,"stream"),"pin_to_top"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"toggle_stream_muted popover-menu-link\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(lookupProperty(depth0,"stream"),"is_muted"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":67,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(lookupProperty(depth0,"stream"),"is_archived"),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":84,"column":19}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});