var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_name", {"start":{"line":4,"column":49},"end":{"line":4,"column":64}} ), depth0))
    + ":</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_realm_emoji"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":8}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "            <img src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "url", {"start":{"line":6,"column":24},"end":{"line":6,"column":32}} ), depth0))
    + "\" class=\"emoji\" />\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_code", {"start":{"line":8,"column":38},"end":{"line":8,"column":53}} ), depth0))
    + "\"></div>\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_reaction_container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_archived"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":73}}})) != null ? stack1 : "")
    + "\">\n    <div class=\""
    + alias4(alias3(alias2(depth0, "class", {"start":{"line":2,"column":18},"end":{"line":2,"column":28}} ), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_archived"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":65}}})) != null ? stack1 : "")
    + "\" aria-label=\""
    + alias4(alias3(alias2(depth0, "label", {"start":{"line":2,"column":81},"end":{"line":2,"column":91}} ), depth0))
    + "\" data-reaction-id=\""
    + alias4(alias3(alias2(depth0, "local_id", {"start":{"line":2,"column":115},"end":{"line":2,"column":128}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"message_reaction_count\">"
    + alias4(alias3(alias2(depth0, "vote_text", {"start":{"line":10,"column":46},"end":{"line":10,"column":60}} ), depth0))
    + "</div>\n    </div>\n</div>\n";
},"useData":true});