var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"edit-bot-buttons\">\n                <button type=\"submit\" class=\"bootstrap-btn open_edit_bot_form tippy-zulip-delayed-tooltip manage-bot-button\" data-sidebar-form=\"edit-bot\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Manage bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":174},"end":{"line":8,"column":193}}}))
    + "\" data-email=\""
    + alias2(alias4(alias3(depth0, "email", {"start":{"line":8,"column":209},"end":{"line":8,"column":214}} ), depth0))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-user-cog blue\" aria-hidden=\"true\"></i>\n                </button>\n                <a type=\"submit\" download=\""
    + alias2(alias4(alias3(depth0, "zuliprc", {"start":{"line":11,"column":45},"end":{"line":11,"column":52}} ), depth0))
    + "\" class=\"bootstrap-btn download_bot_zuliprc tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":147},"end":{"line":11,"column":172}}}))
    + "\" data-email=\""
    + alias2(alias4(alias3(depth0, "email", {"start":{"line":11,"column":188},"end":{"line":11,"column":193}} ), depth0))
    + "\">\n                    <i class=\"fa fa-download sea-green\" aria-hidden=\"true\"></i>\n                </a>\n                <button type=\"submit\" id=\"copy_zuliprc\" class=\"bootstrap-btn copy_zuliprc tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":139},"end":{"line":14,"column":160}}}))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n                </button>\n                <button type=\"submit\" class=\"bootstrap-btn deactivate_bot danger-red tippy-zulip-delayed-tooltip deactivate-bot-button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":156},"end":{"line":17,"column":179}}}))
    + "\" data-user-id=\""
    + alias2(alias4(alias3(depth0, "user_id", {"start":{"line":17,"column":197},"end":{"line":17,"column":204}} ), depth0))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-user-x\" aria-hidden=\"true\"></i>\n                </button>\n                <button type=\"submit\" class=\"bootstrap-btn open_bots_subscribed_streams tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribed channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":137},"end":{"line":20,"column":165}}}))
    + "\" data-user-id=\""
    + alias2(alias4(alias3(depth0, "user_id", {"start":{"line":20,"column":183},"end":{"line":20,"column":190}} ), depth0))
    + "\">\n                    <i class=\"fa fa-hashtag purple\" aria-hidden=\"true\"></i>\n                </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_incoming_webhook_bot"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"submit\" class=\"bootstrap-btn open-generate-integration-url-modal tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Generate URL for an integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":144},"end":{"line":24,"column":183}}}))
    + "\" data-api-key=\""
    + alias1(container.lambda(container.strict(depth0, "api_key", {"start":{"line":24,"column":201},"end":{"line":24,"column":208}} ), depth0))
    + "\">\n                    <i class=\"fa fa-link steel-blue\" aria-hidden=\"true\"></i>\n                </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bot-card-api-key\">\n            <span class=\"bot-card-field\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":41},"end":{"line":43,"column":57}}}))
    + "</span>\n            <div class=\"bot-card-api-key-value-and-button no-select\">\n                <!-- have the `.text-select` in `.no-select` so that the value doesn't have trailing whitespace. -->\n                <span class=\"bot-card-value text-select\">"
    + alias2(alias4(alias3(depth0, "api_key", {"start":{"line":46,"column":59},"end":{"line":46,"column":66}} ), depth0))
    + "</span>\n                <button type=\"submit\" class=\"button no-style bot-card-regenerate-bot-api-key tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":142},"end":{"line":47,"column":171}}}))
    + "\" data-user-id=\""
    + alias2(alias4(alias3(depth0, "user_id", {"start":{"line":47,"column":189},"end":{"line":47,"column":196}} ), depth0))
    + "\">\n                    <i class=\"fa fa-refresh\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n            <div class=\"bot-card-api-key-error text-error\"></div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button round button-warning reactivate_bot\" data-user-id=\""
    + alias1(container.lambda(container.strict(depth0, "user_id", {"start":{"line":54,"column":83},"end":{"line":54,"column":90}} ), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":94},"end":{"line":54,"column":117}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"bot-information-box white-box\">\n    <div class=\"bot-card-image overflow-hidden\">\n        <img src=\""
    + alias3(alias2(alias1(depth0, "avatar_url", {"start":{"line":3,"column":20},"end":{"line":3,"column":30}} ), depth0))
    + "\" class=\"bot-card-avatar\" />\n        <div class=\"bot-card-details\">\n            <div class=\"bot-card-name\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":5,"column":41},"end":{"line":5,"column":45}} ), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"bot-card-info\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":32,"column":47},"end":{"line":32,"column":54}} ), depth0))
    + "\">\n        <div class=\"bot-card-type\">\n            <div class=\"bot-card-field\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":57}}}))
    + "</div>\n            <div class=\"bot-card-value\">"
    + alias3(alias2(alias1(depth0, "type", {"start":{"line":35,"column":42},"end":{"line":35,"column":46}} ), depth0))
    + "</div>\n        </div>\n        <div class=\"bot-card-email\">\n            <div class=\"bot-card-field\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Bot email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":40},"end":{"line":38,"column":58}}}))
    + "</div>\n            <div class=\"bot-card-value\">"
    + alias3(alias2(alias1(depth0, "email", {"start":{"line":39,"column":42},"end":{"line":39,"column":47}} ), depth0))
    + "</div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</li>\n";
},"useData":true});