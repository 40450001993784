var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":1,"column":38},"end":{"line":1,"column":40}} ), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "custom_classes", {"start":{"line":1,"column":82},"end":{"line":1,"column":96}} ), depth0))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    return "icon-button-square ";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-tooltip-template-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "data-tooltip-template-id", {"start":{"line":1,"column":269},"end":{"line":1,"column":293}} ), depth0))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "aria-label=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "aria-label", {"start":{"line":1,"column":349},"end":{"line":1,"column":359}} ), depth0))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "data-tippy-content=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "data-tippy-content", {"start":{"line":2,"column":50},"end":{"line":2,"column":68}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":50}}})) != null ? stack1 : "")
    + " class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"custom_classes"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + "icon-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"squared"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":159}}})) != null ? stack1 : "")
    + "icon-button-"
    + alias4(alias3(alias2(depth0, "intent", {"start":{"line":1,"column":173},"end":{"line":1,"column":179}} ), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hidden"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":182},"end":{"line":1,"column":207}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"data-tooltip-template-id"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":209},"end":{"line":1,"column":303}}})) != null ? stack1 : "")
    + " tabindex=\"0\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"aria-label"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":317},"end":{"line":1,"column":369}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"data-tippy-content"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":78}}})) != null ? stack1 : "")
    + "\n  >\n    <i class=\"zulip-icon zulip-icon-"
    + alias4(alias3(alias2(depth0, "icon", {"start":{"line":4,"column":38},"end":{"line":4,"column":42}} ), depth0))
    + "\" aria-hidden=\"true\"></i>\n</button>\n";
},"useData":true});