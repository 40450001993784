var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <li role=\"none\" class=\"display-style-selector link-item\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":11,"column":78},"end":{"line":11,"column":87}} ), depth0))
    + "\">\n                <label class=\"popover-menu-link\" role=\"menuitem\" tabindex=\"0\">\n                    <input type=\"radio\" class=\"user_list_style_choice\" name=\"user_list_style\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":13,"column":103},"end":{"line":13,"column":112}} ), depth0))
    + "\"/>\n                    <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":14,"column":55},"end":{"line":14,"column":71}} ), depth0))
    + "</span>\n                </label>\n            </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"invite-user-link-item link-item\">\n            <a class=\"invite-user-link popover-menu-link\" role=\"menuitem\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":57}}}))
    + "\n                </span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"buddy-list-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item\">\n            <label class=\"display-style-selector-header popover-menu-link\" role=\"menuitem\">\n                <span class=\"popover-menu-label\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"User list style",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":44}}}))
    + "\n                </span>\n            </label>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"display_style_options"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_invite_users"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});