var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You can only view or manage invitations that you sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":84}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th data-sort=\"alphabetic\" data-sort-prop=\"referrer_name\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invited by",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":74},"end":{"line":24,"column":93}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-invites-list\" class=\"user-settings-section\" data-user-settings-section=\"invitations\">\n    <div class=\"tip invite-user-settings-tip\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "    <a class=\"invite-user-link\" role=\"button\">\n        <i class=\"zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n        <span class=\"user-settings-invite-user-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":54},"end":{"line":8,"column":91}}}))
    + "</span>\n    </a>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitations ",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":32}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"invites-field-status\"></div>\n        "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter invitations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":66},"end":{"line":14,"column":90}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":54}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"invitee\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":55},"end":{"line":20,"column":71}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + "                <th data-sort=\"numeric\" data-sort-prop=\"invited\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invited at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":84}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th data-sort=\"numeric\" data-sort-prop=\"expiry_date\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expires at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":69},"end":{"line":31,"column":88}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th data-sort=\"numeric\" data-sort-prop=\"invited_as\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invited as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":68},"end":{"line":34,"column":87}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":37,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_invites_table\" class=\"admin_invites_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no invitations.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":84},"end":{"line":39,"column":118}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No invitations match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":147},"end":{"line":39,"column":197}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_invites_loading_indicator\"></div>\n</div>\n";
},"usePartial":true,"useData":true});