var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"save-button-controls hide\">\n    <div class=\"inline-block subsection-changes-save\">\n        <button class=\"save-discard-widget-button button primary save-button\" data-status=\"save\">\n            <span class=\"fa fa-spinner fa-spin save-discard-widget-button-loading\"></span>\n            <span class=\"fa fa-check save-discard-widget-button-icon\"></span>\n            <span class=\"save-discard-widget-button-text\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Save changes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":37}}}))
    + "\n            </span>\n        </button>\n    </div>\n    <div class=\"inline-block subsection-changes-discard\">\n        <button class=\"save-discard-widget-button button discard-button\">\n            <span class=\"fa fa-times save-discard-widget-button-icon\"></span>\n            <span class=\"save-discard-widget-button-text\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Discard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":32}}}))
    + "\n            </span>\n        </button>\n    </div>\n    <div class=\"inline-block subsection-failed-status\"><p class=\"hide\"></p></div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert-notification "
    + container.escapeExpression(container.lambda(container.strict(depth0, "section_name", {"start":{"line":23,"column":33},"end":{"line":23,"column":45}} ), depth0))
    + "-status\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_only_indicator"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":24,"column":11}}})) != null ? stack1 : "");
},"useData":true});