var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"topic_search_section filter-topics left-sidebar-filter-row\">\n    <input class=\"topic-list-filter home-page-input filter_text_input\" id=\"filter-topic-input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Filter topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":139},"end":{"line":2,"column":160}}}))
    + "\" />\n    <button type=\"button\" class=\"clear_search_button\" id=\"clear_search_topic_button\">\n        <i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n";
},"useData":true});