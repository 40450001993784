var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\n    <label for=\"edit_service_base_url\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Endpoint URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":60}}}))
    + "</label>\n    <input id=\"edit_service_base_url\" type=\"text\" name=\"service_payload_url\" class=\"edit_service_base_url required modal_text_input\"value=\""
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"service"), "base_url", {"start":{"line":3,"column":141},"end":{"line":3,"column":157}} ), depth0))
    + "\" maxlength=2083 />\n    <div><label for=\"edit_service_base_url\" generated=\"true\" class=\"text-error\"></label></div>\n</div>\n<div class=\"input-group\">\n    <label for=\"edit_service_interface\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Interface",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":58}}}))
    + "</label>\n    <select id=\"edit_service_interface\" class=\"modal_select bootstrap-focus-style\" name=\"service_interface\">\n        <option value=\"1\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":42}}}))
    + "</option>\n        <option value=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Slack's outgoing webhooks",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":60}}}))
    + "</option>\n    </select>\n</div>\n";
},"useData":true});