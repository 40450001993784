var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":lookupProperty(depth0,"help_link_widget_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"language_selection_widget input-group prop-element\" id=\"id_"
    + alias3(alias2(alias1(depth0, "section_name", {"start":{"line":1,"column":73},"end":{"line":1,"column":85}} ), depth0))
    + "\" data-setting-widget-type=\"language-setting\">\n    <label class=\"settings-field-label\" for=\"id_language_selection_button\">\n        "
    + alias3(alias2(alias1(depth0, "section_title", {"start":{"line":3,"column":10},"end":{"line":3,"column":23}} ), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"help_link_widget_link"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n    <button type=\"button\" id=\"id_language_selection_button\" class=\"language_selection_button button rounded tippy-zulip-delayed-tooltip\" data-section=\""
    + alias3(alias2(alias1(depth0, "section_name", {"start":{"line":8,"column":153},"end":{"line":8,"column":165}} ), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Change language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":189},"end":{"line":8,"column":213}}}))
    + "\">\n        <span class=\""
    + alias3(alias2(alias1(depth0, "section_name", {"start":{"line":9,"column":23},"end":{"line":9,"column":35}} ), depth0))
    + "\" data-language-code=\""
    + alias3(alias2(alias1(depth0, "language_code", {"start":{"line":9,"column":61},"end":{"line":9,"column":74}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "setting_value", {"start":{"line":9,"column":80},"end":{"line":9,"column":93}} ), depth0))
    + "</span>\n    </button>\n</div>\n";
},"usePartial":true,"useData":true});