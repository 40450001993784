var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":32}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"subscriber_list_container\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"subscriber_list_loading_indicator\"></div>\n    <table id=\"stream_members_list\" class=\"subscriber-list table table-striped\">\n        <thead class=\"table-sticky-headers\">\n            <th data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":79}}}))
    + "\n                <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n            </th>\n            <th class=\"settings-email-column\" data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":78}}}))
    + "\n                <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n            </th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_remove_subscribers"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "        </thead>\n        <tbody class=\"subscriber_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This channel has no subscribers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":52},"end":{"line":15,"column":93}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No channel subscribers match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":122},"end":{"line":15,"column":179}}}))
    + "\"></tbody>\n    </table>\n</div>\n";
},"useData":true});