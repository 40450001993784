var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<template id=\"view-user-card-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"U",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":32}}}))
    + "\n</template>\n<template id=\"view-bot-card-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View bot card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"U",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":32}}}))
    + "\n</template>\n<template id=\"scroll-to-bottom-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll to bottom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":29}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"End",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":34}}}))
    + "\n</template>\n<template id=\"compose_disable_stream_reply_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You do not have permission to post in this channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":64}}}))
    + "\n</template>\n<template id=\"compose_reply_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":38}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":32}}}))
    + "\n</template>\n<template id=\"compose_reply_selected_topic_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":43}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":32}}}))
    + "\n</template>\n<template id=\"left_bar_compose_mobile_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Start new conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":35}}}))
    + "\n</template>\n<template id=\"new_topic_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":28,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":29,"column":32}}}))
    + "\n</template>\n<template id=\"new_stream_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New channel message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":32,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":33,"column":32}}}))
    + "\n</template>\n<template id=\"new_direct_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":36,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"X",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":32}}}))
    + "\n</template>\n<template id=\"compose_close_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":40,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":41,"column":34}}}))
    + "\n</template>\n<template id=\"compose_close_and_save_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":44,"column":42}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":45,"column":34}}}))
    + "\n</template>\n<template id=\"send-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":48,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":49,"column":36}}}))
    + "\n</template>\n<template id=\"send-ctrl-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":4},"end":{"line":52,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":53,"column":43}}}))
    + "\n</template>\n<template id=\"preview-tooltip\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":56,"column":20}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Alt","P",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":57,"column":38}}}))
    + "\n</template>\n<template id=\"add-global-time-tooltip\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":13},"end":{"line":61,"column":37}}}))
    + "</div>\n        <div class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Everyone sees global times in their own time zone.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":50},"end":{"line":62,"column":109}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"add-poll-tooltip\">\n    <div>\n        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add poll",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":14},"end":{"line":67,"column":31}}}))
    + "</span><br/>\n        <span class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A poll must be an entire message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":51},"end":{"line":68,"column":93}}}))
    + "</span>\n    </div>\n</template>\n<template id=\"add-todo-tooltip\">\n    <div>\n        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add to-do list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":14},"end":{"line":73,"column":37}}}))
    + "</span><br/>\n        <span class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A to-do list must be an entire message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":51},"end":{"line":74,"column":99}}}))
    + "</span>\n    </div>\n</template>\n<template id=\"add-saved-snippet-tooltip\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add saved snippet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":4},"end":{"line":78,"column":30}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","'",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":79,"column":4},"end":{"line":79,"column":39}}}))
    + "\n</template>\n<template id=\"link-tooltip\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":82,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","Shift","L",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":83,"column":4},"end":{"line":83,"column":47}}}))
    + "\n</template>\n<template id=\"bold-tooltip\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":4},"end":{"line":86,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","B",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":87,"column":4},"end":{"line":87,"column":39}}}))
    + "\n</template>\n<template id=\"italic-tooltip\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Italic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":4},"end":{"line":90,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":91,"column":4},"end":{"line":91,"column":39}}}))
    + "\n</template>\n<template id=\"delete-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":4},"end":{"line":94,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":95,"column":4},"end":{"line":95,"column":40}}}))
    + "\n</template>\n<template id=\"restore-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restore draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":4},"end":{"line":98,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":99,"column":4},"end":{"line":99,"column":36}}}))
    + "\n</template>\n<template id=\"gear-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Main menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":4},"end":{"line":102,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":103,"column":4},"end":{"line":103,"column":32}}}))
    + "\n</template>\n<template id=\"personal-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Personal menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":4},"end":{"line":106,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","→",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":107,"column":4},"end":{"line":107,"column":36}}}))
    + "\n</template>\n<template id=\"help-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":4},"end":{"line":110,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","←",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":111,"column":4},"end":{"line":111,"column":36}}}))
    + "\n</template>\n<template id=\"automatic-theme-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":13},"end":{"line":115,"column":37}}}))
    + "</div>\n        <div class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follows system settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":50},"end":{"line":116,"column":83}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"all-message-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"all_messages\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Combined feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":13},"end":{"line":121,"column":35}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic hidden-for-spectators\"></div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":84},"end":{"line":123,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"A",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":125,"column":4},"end":{"line":125,"column":32}}}))
    + "\n</template>\n<template id=\"recent-conversations-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"recent_topics\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":13},"end":{"line":129,"column":42}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic hidden-for-spectators\"></div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide hidden-for-spectators\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":106},"end":{"line":131,"column":138}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"T",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":133,"column":4},"end":{"line":133,"column":32}}}))
    + "\n</template>\n<template id=\"starred-message-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"starred_message\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":13},"end":{"line":137,"column":38}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic\"></div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"*",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":140,"column":4},"end":{"line":140,"column":32}}}))
    + "\n</template>\n<template id=\"my-reactions-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"recent_topics\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reactions to your messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":13},"end":{"line":144,"column":48}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"inbox-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"inbox\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":149,"column":13},"end":{"line":149,"column":27}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic\"></div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":84},"end":{"line":151,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":153,"column":40}}}))
    + "\n</template>\n<template id=\"drafts-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"drafts\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":13},"end":{"line":157,"column":28}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic\"></div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"D",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":160,"column":4},"end":{"line":160,"column":32}}}))
    + "\n</template>\n<template id=\"scheduled-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"scheduled_message\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":13},"end":{"line":164,"column":39}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic\"></div>\n    </div>\n</template>\n<template id=\"show-all-direct-messages-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Direct message feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":169,"column":4},"end":{"line":169,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","P",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":170,"column":4},"end":{"line":170,"column":40}}}))
    + "\n</template>\n<template id=\"mentions-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"mentions\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":174,"column":13},"end":{"line":174,"column":30}}}))
    + "</div>\n        <div class=\"tootlip-inner-content views-message-count italic\"></div>\n    </div>\n</template>\n<template id=\"filter-streams-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":4},"end":{"line":179,"column":28}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":180,"column":4},"end":{"line":180,"column":32}}}))
    + "\n</template>\n<template id=\"message-expander-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":183,"column":4},"end":{"line":183,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":184,"column":4},"end":{"line":184,"column":32}}}))
    + "\n</template>\n<template id=\"message-condenser-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show less",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":4},"end":{"line":187,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":188,"column":4},"end":{"line":188,"column":32}}}))
    + "\n</template>\n<template id=\"edit-content-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":4},"end":{"line":191,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"E",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":192,"column":4},"end":{"line":192,"column":32}}}))
    + "\n</template>\n<template id=\"move-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":195,"column":4},"end":{"line":195,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":196,"column":4},"end":{"line":196,"column":32}}}))
    + "\n</template>\n<template id=\"add-emoji-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":199,"column":4},"end":{"line":199,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,":",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":200,"column":4},"end":{"line":200,"column":32}}}))
    + "\n</template>\n<template id=\"message-actions-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":203,"column":4},"end":{"line":203,"column":28}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":204,"column":4},"end":{"line":204,"column":32}}}))
    + "\n</template>\n<template id=\"dismiss-failed-send-button-tooltip-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dismiss failed message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":208,"column":13},"end":{"line":208,"column":44}}}))
    + "</div>\n        <div class=\"italic tooltip-inner-content\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"This content remains saved in your drafts.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":210,"column":12},"end":{"line":210,"column":63}}}))
    + "\n        </div>\n    </div>\n</template>\n<template id=\"slow-send-spinner-tooltip-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sending…",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":216,"column":13},"end":{"line":216,"column":30}}}))
    + "</div>\n        <div class=\"italic\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"This message will remain saved in your drafts until it is successfully sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":218,"column":12},"end":{"line":218,"column":97}}}))
    + "\n        </div>\n    </div>\n</template>\n<template id=\"star-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Star this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":223,"column":32},"end":{"line":223,"column":58}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":224,"column":4},"end":{"line":224,"column":39}}}))
    + "\n</template>\n<template id=\"unstar-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unstar this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":227,"column":32},"end":{"line":227,"column":60}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":228,"column":4},"end":{"line":228,"column":39}}}))
    + "\n</template>\n<template id=\"search-query-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":231,"column":4},"end":{"line":231,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"/",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":232,"column":4},"end":{"line":232,"column":32}}}))
    + "\n</template>\n<template id=\"show-left-sidebar-tooltip-template\" >\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show left sidebar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":235,"column":4},"end":{"line":235,"column":30}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":236,"column":4},"end":{"line":236,"column":32}}}))
    + "\n</template>\n<template id=\"hide-left-sidebar-tooltip-template\" >\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide left sidebar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":239,"column":4},"end":{"line":239,"column":30}}}))
    + "\n</template>\n<template id=\"show-userlist-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show user list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":242,"column":4},"end":{"line":242,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"W",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":243,"column":4},"end":{"line":243,"column":32}}}))
    + "\n</template>\n<template id=\"hide-userlist-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide user list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":246,"column":4},"end":{"line":246,"column":27}}}))
    + "\n</template>\n<template id=\"topic-unmute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":4},"end":{"line":249,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":250,"column":4},"end":{"line":250,"column":40}}}))
    + "\n</template>\n<template id=\"topic-mute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":253,"column":4},"end":{"line":253,"column":23}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":254,"column":4},"end":{"line":254,"column":40}}}))
    + "\n</template>\n<template id=\"restore-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit and reschedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":257,"column":4},"end":{"line":257,"column":40}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":258,"column":4},"end":{"line":258,"column":36}}}))
    + "\n</template>\n<template id=\"delete-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete scheduled message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":261,"column":4},"end":{"line":261,"column":37}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":262,"column":4},"end":{"line":262,"column":40}}}))
    + "\n</template>\n<template id=\"create-new-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create new channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":265,"column":4},"end":{"line":265,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"N",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":266,"column":4},"end":{"line":266,"column":32}}}))
    + "\n</template>\n<template id=\"show-subscribe-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribe to this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":269,"column":4},"end":{"line":269,"column":38}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":270,"column":4},"end":{"line":270,"column":40}}}))
    + "\n</template>\n<template id=\"show-unsubscribe-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unsubscribe from this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":273,"column":4},"end":{"line":273,"column":42}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":274,"column":4},"end":{"line":274,"column":40}}}))
    + "\n</template>\n<template id=\"view-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":277,"column":4},"end":{"line":277,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","V",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":278,"column":4},"end":{"line":278,"column":40}}}))
    + "\n</template>\n<template id=\"mobile-push-notification-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile push notifications are not enabled on this server.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":281,"column":4},"end":{"line":281,"column":70}}}))
    + "\n</template>\n<template id=\"color-picker-confirm-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Confirm new color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":284,"column":4},"end":{"line":284,"column":30}}}))
    + "\n</template>\n<template id=\"save-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":287,"column":4},"end":{"line":287,"column":17}}}))
    + "\n</template>\n<template id=\"cancel-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":290,"column":4},"end":{"line":290,"column":19}}}))
    + "\n</template>\n";
},"useData":true});