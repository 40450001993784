var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li role=\"none\" class=\"popover-menu-list-item text-item custom_user_field\" data-type=\""
    + alias3(alias2(alias1(depth0, "type", {"start":{"line":2,"column":92},"end":{"line":2,"column":101}} ), depth0))
    + "\" data-field-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":122},"end":{"line":2,"column":129}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_link"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":4,"column":23},"end":{"line":4,"column":33}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"custom-profile-field-value custom-profile-field-link\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":4,"column":162},"end":{"line":4,"column":171}} ), depth0))
    + "\" tabindex=\"0\">\n                <span class=\"custom-profile-field-text\">"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":5,"column":58},"end":{"line":5,"column":68}} ), depth0))
    + "</span>\n            </a>\n            <span role=\"menuitem\" tabindex=\"0\" class=\"popover-menu-link copy-button copy-custom-profile-field-link\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":128},"end":{"line":7,"column":145}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":167},"end":{"line":7,"column":184}}}))
    + "\">\n                <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n            </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_external_account"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":27,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias3(alias2(alias1(depth0, "link", {"start":{"line":11,"column":23},"end":{"line":11,"column":32}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"custom-profile-field-value custom-profile-field-link\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":11,"column":161},"end":{"line":11,"column":170}} ), depth0))
    + "\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"subtype"),"github",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":48}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"custom-profile-field-text\">"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":17,"column":58},"end":{"line":17,"column":68}} ), depth0))
    + "</span>\n            </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"popover-menu-icon fa fa-github\" aria-hidden=\"true\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"subtype"),"twitter",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":53}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":16}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"popover-menu-icon fa fa-twitter\" aria-hidden=\"true\"></i>\n                ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"rendered_value"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":27,"column":8}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"custom-profile-field-value rendered_markdown\" data-tippy-content=\""
    + alias1(container.lambda(container.strict(depth0, "name", {"start":{"line":20,"column":92},"end":{"line":20,"column":101}} ), depth0))
    + "\">\n                "
    + alias1(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"rendered_value"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":57}}}))
    + "\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <div class=\"custom-profile-field-value\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":24,"column":74},"end":{"line":24,"column":83}} ), depth0))
    + "\">\n                <span class=\"custom-profile-field-text\">"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":25,"column":58},"end":{"line":25,"column":68}} ), depth0))
    + "</span>\n            </div>\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"profile_fields"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":29,"column":9}}})) != null ? stack1 : "");
},"useData":true});