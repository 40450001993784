var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":6,"column":50},"end":{"line":6,"column":60}} ), depth0))
    + "\" class=\"respond_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-quote-message\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Quote message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":71}}}))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(alias4,">",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":44}}}))
    + "\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":13,"column":50},"end":{"line":13,"column":60}} ), depth0))
    + "\" class=\"forward_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-forward-message\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Forward message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":73}}}))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(alias4,"<",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":26,"column":50},"end":{"line":26,"column":60}} ), depth0))
    + "\" class=\"popover_edit_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "editability_menu_item", {"start":{"line":28,"column":51},"end":{"line":28,"column":72}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":35,"column":50},"end":{"line":35,"column":60}} ), depth0))
    + "\" class=\"popover_move_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "move_message_menu_item", {"start":{"line":37,"column":51},"end":{"line":37,"column":73}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"M",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":44,"column":50},"end":{"line":44,"column":60}} ), depth0))
    + "\" class=\"delete_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":49},"end":{"line":46,"column":72}}}))
    + "</span>\n            </a>\n        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":54,"column":50},"end":{"line":54,"column":60}} ), depth0))
    + "\" class=\"reaction_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":49},"end":{"line":56,"column":76}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,":",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":57,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":67,"column":50},"end":{"line":67,"column":60}} ), depth0))
    + "\" class=\"mark_as_unread popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as unread from here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":49},"end":{"line":69,"column":82}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"Shift","U",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":70,"column":52}}}))
    + "\n            </a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":76,"column":50},"end":{"line":76,"column":60}} ), depth0))
    + "\" class=\"rehide_muted_user_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-hide\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Hide muted message again",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":49},"end":{"line":78,"column":82}}}))
    + "</span>\n            </a>\n        </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":84,"column":50},"end":{"line":84,"column":60}} ), depth0))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-collapse\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Collapse message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":49},"end":{"line":86,"column":74}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":87,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":93,"column":50},"end":{"line":93,"column":60}} ), depth0))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-expand\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Expand message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":49},"end":{"line":95,"column":72}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":96,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":104,"column":50},"end":{"line":104,"column":60}} ), depth0))
    + "\" class=\"popover_view_source popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-source\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "view_source_menu_item", {"start":{"line":106,"column":51},"end":{"line":106,"column":72}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":107,"column":16},"end":{"line":107,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":113,"column":50},"end":{"line":113,"column":60}} ), depth0))
    + "\" class=\"view_read_receipts popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-readreceipts\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":49},"end":{"line":115,"column":76}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"Shift","V",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":116,"column":16},"end":{"line":116,"column":52}}}))
    + "\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"message-actions-menu-dropdown\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_quote_message"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"editability_menu_item"),lookupProperty(depth0,"move_message_menu_item"),lookupProperty(depth0,"should_display_delete_option"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":21,"column":14},"end":{"line":21,"column":92}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"editability_menu_item"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"move_message_menu_item"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_delete_option"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_add_reaction_option"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),lookupProperty(depth0,"should_display_hide_option"),lookupProperty(depth0,"should_display_collapse"),lookupProperty(depth0,"should_display_uncollapse"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":62,"column":14},"end":{"line":62,"column":125}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":73,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_hide_option"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_collapse"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":90,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_uncollapse"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":99,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"view_source_menu_item"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":8},"end":{"line":110,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_read_receipts_option"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":8},"end":{"line":119,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"copy_link navigate-link-on-enter popover-menu-link\" data-message-id=\""
    + alias4(alias3(alias2(depth0, "message_id", {"start":{"line":121,"column":109},"end":{"line":121,"column":119}} ), depth0))
    + "\" data-clipboard-text=\""
    + alias4(alias3(alias2(depth0, "conversation_time_url", {"start":{"line":121,"column":147},"end":{"line":121,"column":168}} ), depth0))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":49},"end":{"line":123,"column":78}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});