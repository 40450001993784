var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Leave group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":40}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Join group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":39}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button small rounded button-danger deactivate deactivate-group-button tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deactivate group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":142},"end":{"line":14,"column":166}}}))
    + "\" type=\"button\"> <i class=\"zulip-icon zulip-icon-user-group-x\" aria-hidden=\"true\"></i></button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group_settings_header\" data-group-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "id", {"start":{"line":1,"column":52},"end":{"line":1,"column":60}} ), depth0))
    + "\">\n    <div class=\"tab-container\"></div>\n    <div class=\"button-group\">\n        <div class=\"join_leave_button_wrapper inline-block\">\n            <button class=\"button small rounded join_leave_button\" type=\"button\" name=\"button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_direct_member"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(lookupProperty(depth0,"group"),"deactivated"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"user_group_settings_wrapper\" data-group-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "id", {"start":{"line":18,"column":58},"end":{"line":18,"column":66}} ), depth0))
    + "\">\n    <div class=\"inner-box\">\n\n        <div class=\"group_general_settings group_setting_section\" data-group-section=\"general\">\n            <div class=\"group-banner\"></div>\n            <div class=\"group-header\">\n                <div class=\"group-name-wrapper\">\n                    <span class=\"group-name\" title=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "name", {"start":{"line":25,"column":54},"end":{"line":25,"column":64}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "name", {"start":{"line":25,"column":70},"end":{"line":25,"column":80}} ), depth0))
    + "</span>\n                </div>\n                <div class=\"group_change_property_info alert-notification\"></div>\n                <div class=\"button-group\">\n                    <button id=\"open_group_info_modal\" class=\"button rounded small button-warning tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Change group info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":147},"end":{"line":29,"column":173}}}))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-user-group-edit\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"group-description-wrapper\">\n                <span class=\"group-description\">\n                    "
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "description", {"start":{"line":36,"column":22},"end":{"line":36,"column":39}} ), depth0))
    + "\n                </span>\n            </div>\n\n            <div class=\"group-permissions settings-subsection-parent\" id=\"group_permission_settings\">\n                <div class=\"subsection-header\">\n                    <h3 class=\"user_group_setting_subsection_title\">\n                        "
    + alias3(lookupProperty(helpers,"t").call(alias4,"Group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":50}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/manage-user-groups#configure-group-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </h3>\n                    "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),undefined,{"name":"../settings/settings_save_discard_widget","hash":{"section_name":"group-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                "
    + ((stack1 = container.invokePartial(require("./group_permissions.hbs"),undefined,{"name":"group_permissions","hash":{"group_setting_labels":lookupProperty(lookupProperty(depth0,"all_group_setting_labels"),"group"),"prefix":"id_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"group_detail_box\">\n                <div class=\"user_group_details_box_header\">\n                    <h3 class=\"user_group_setting_subsection_title\">\n                        "
    + alias3(lookupProperty(helpers,"t").call(alias4,"User group details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":51}}}))
    + "\n                    </h3>\n                </div>\n                <div class=\"creator_details group_details_box_subsection\">\n                    "
    + ((stack1 = container.invokePartial(require("../creator_details.hbs"),depth0,{"name":"../creator_details","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"group_details_box_subsection\">\n                    "
    + alias3(lookupProperty(helpers,"t").call(alias4,"User group ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":41}}}))
    + "<br/>\n                    "
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "id", {"start":{"line":63,"column":22},"end":{"line":63,"column":30}} ), depth0))
    + "\n                </div>\n            </div>\n        </div>\n\n        <div class=\"group_member_settings group_setting_section\" data-group-section=\"members\">\n            <div class=\"edit_members_for_user_group\">\n                "
    + ((stack1 = container.invokePartial(require("./user_group_members.hbs"),depth0,{"name":"user_group_members","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"group_setting_section\" data-group-section=\"permissions\">\n            <div class=\"group-assigned-permissions\">\n                <span class=\"no-permissions-for-group-text "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"group_has_no_permissions"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":59},"end":{"line":76,"column":110}}})) != null ? stack1 : "")
    + "\">\n                    "
    + alias3(lookupProperty(helpers,"t").call(alias4,"This group has no assigned permissions.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":20},"end":{"line":77,"column":67}}}))
    + "\n                </span>\n                "
    + ((stack1 = container.invokePartial(require("./group_permission_settings.hbs"),depth0,{"name":"group_permission_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});