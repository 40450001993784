var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li data-type=\""
    + alias3(alias2(alias1(depth0, "type", {"start":{"line":2,"column":21},"end":{"line":2,"column":30}} ), depth0))
    + "\" class=\"field-section custom_user_field\" data-field-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":91},"end":{"line":2,"column":98}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depths[1],"for_user_card_popover"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_link"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":4,"column":32},"end":{"line":4,"column":41}} ), depth0))
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"custom-user-url-field\">\n                <a tabindex=\"0\" href=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":8,"column":40},"end":{"line":8,"column":50}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value custom-profile-fields-link "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depths[1],"for_user_card_popover"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":136},"end":{"line":8,"column":194}}})) != null ? stack1 : "")
    + "\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":218},"end":{"line":8,"column":227}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":8,"column":233},"end":{"line":8,"column":243}} ), depth0))
    + "</a>\n                <span tabindex=\"0\" class=\"copy-button copy-custom-field-url tippy-zulip-tooltip\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":109},"end":{"line":9,"column":126}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":148},"end":{"line":9,"column":165}}}))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n                </span>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "tippy-zulip-tooltip";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_external_account"),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a tabindex=\"0\" href=\""
    + alias3(alias2(alias1(depth0, "link", {"start":{"line":14,"column":36},"end":{"line":14,"column":45}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value custom-profile-fields-link "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depths[1],"for_user_card_popover"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":131},"end":{"line":14,"column":189}}})) != null ? stack1 : "")
    + "\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":14,"column":213},"end":{"line":14,"column":222}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"subtype"),"github",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":48}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "                "
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":20,"column":18},"end":{"line":20,"column":28}} ), depth0))
    + "\n            </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"fa fa-github\" aria-hidden=\"true\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"subtype"),"twitter",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":53}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":16}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"fa fa-twitter\" aria-hidden=\"true\"></i>\n                ";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_user_field"),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"pill-container not-editable\" data-field-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":23,"column":70},"end":{"line":23,"column":77}} ), depth0))
    + "\">\n                <div class=\"input\" contenteditable=\"false\" style=\"display: none;\"></div>\n            </div>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"rendered_value"),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(20, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "        ";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"value rendered_markdown "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depths[1],"for_user_card_popover"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":107}}})) != null ? stack1 : "")
    + "\" data-tippy-content=\""
    + alias2(container.lambda(container.strict(depth0, "name", {"start":{"line":28,"column":131},"end":{"line":28,"column":140}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"rendered_markdown").call(alias1,lookupProperty(depth0,"rendered_value"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":28,"column":144},"end":{"line":28,"column":185}}}))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"value "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depths[1],"for_user_card_popover"),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":90}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":30,"column":113},"end":{"line":30,"column":122}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":30,"column":128},"end":{"line":30,"column":138}} ), depth0))
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "tippy-zulip-tooltip\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"profile_fields"),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});