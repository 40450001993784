var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-email=\""
    + alias1(container.lambda(container.strict(depth0, "email", {"start":{"line":1,"column":18},"end":{"line":1,"column":28}} ), depth0))
    + "\" class=\"typing_notification\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"{full_name} is typing…",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":91}}}))
    + "</li>\n";
},"useData":true});