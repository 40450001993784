var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"/upgrade/\" class=\"upgrade-tip\" target=\"_blank\" rel=\"noopener noreferrer\">\n        "
    + alias1(container.lambda(container.strict(depth0, "upload_quota_string", {"start":{"line":4,"column":10},"end":{"line":4,"column":29}} ), depth0))
    + " "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade for more space.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":64}}}))
    + "\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"tip\">\n        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "upload_quota_string", {"start":{"line":8,"column":10},"end":{"line":8,"column":29}} ), depth0))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_upgrade_message"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});