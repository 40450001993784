var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"giphy_grid_in_popover\">\n    <div class=\"arrow\"></div>\n    <div class=\"popover-inner\">\n        <div class=\"popover-filter-input-wrapper\">\n            <input type=\"text\" id=\"giphy-search-query\" class=\"popover-filter-input filter_text_input\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":134},"end":{"line":5,"column":149}}}))
    + "\" autofocus/>\n        </div>\n        <div class=\"giphy-scrolling-container\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <div class=\"giphy-content\"></div>\n        </div>\n        <div class=\"popover-footer\">\n            <img src=\"" + require("../images/giphy/GIPHY_attribution.png") + "\" alt=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"GIPHY attribution",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":92}}}))
    + "\" />\n        </div>\n    </div>\n</div>\n";
},"useData":true});