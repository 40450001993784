var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "type_id", {"start":{"line":10,"column":37},"end":{"line":10,"column":49}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":10,"column":55},"end":{"line":10,"column":64}} ), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":18,"column":37},"end":{"line":18,"column":46}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":18,"column":52},"end":{"line":18,"column":61}} ), depth0))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"config"),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":57,"column":25}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = container.invokePartial(require("../embedded_bot_config_item.hbs"),undefined,{"name":"../embedded_bot_config_item","hash":{"value":depth0,"key":lookupProperty(data,"key"),"botname":lookupProperty(depths[1],"name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"create_bot_form\">\n    <div class=\"new-bot-form\">\n        <div class=\"input-group\">\n            <label for=\"create_bot_type\" class=\"modal-field-label\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":33}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/bots-overview#bot-type"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"bot_type\" id=\"create_bot_type\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"bot_types"),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\" id=\"service_name_list\">\n            <label for=\"select_service_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":71},"end":{"line":15,"column":82}}}))
    + "</label>\n            <select name=\"service_name\" id=\"select_service_name\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"realm_embedded_bots"),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"create_bot_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":67},"end":{"line":23,"column":80}}}))
    + "</label>\n            <input type=\"text\" name=\"bot_name\" id=\"create_bot_name\" class=\"required modal_text_input\"\n              maxlength=100 placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cookie Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":60}}}))
    + "\" value=\"\" />\n            <div><label for=\"create_bot_name\" generated=\"true\" class=\"text-error\"></label></div>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"create_bot_short_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot email (a-z, 0-9, and dashes only)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":73},"end":{"line":29,"column":119}}}))
    + "</label>\n            <input type=\"text\" name=\"bot_short_name\" id=\"create_bot_short_name\" class=\"required bot_local_part modal_text_input\"\n              placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"cookie",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":27},"end":{"line":31,"column":42}}}))
    + "\" value=\"\" />\n            -bot@"
    + alias2(container.lambda(container.strict(depth0, "realm_bot_domain", {"start":{"line":32,"column":20},"end":{"line":32,"column":36}} ), depth0))
    + "\n            <div>\n                <label for=\"create_bot_short_name\" generated=\"true\" class=\"text-error\"></label>\n            </div>\n        </div>\n        <div id=\"payload_url_inputbox\">\n            <div class=\"input-group\">\n                <label for=\"create_payload_url\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Endpoint URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":74},"end":{"line":39,"column":95}}}))
    + "</label>\n                <input type=\"text\" name=\"payload_url\" id=\"create_payload_url\" class=\"modal_text_input\"\n                  maxlength=2083 placeholder=\"https://hostname.example.com\" value=\"\" />\n                <div><label for=\"create_payload_url\" generated=\"true\" class=\"text-error\"></label></div>\n            </div>\n            <div class=\"input-group\">\n                <label for=\"interface_type\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Outgoing webhook message format",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":70},"end":{"line":45,"column":110}}}))
    + "</label>\n                <select name=\"interface_type\" id=\"create_interface_type\" class=\"modal_select bootstrap-focus-style\">\n                    <option value=\"1\">Zulip</option>\n                    <option value=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Slack compatible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":38},"end":{"line":48,"column":63}}}))
    + "</option>\n                </select>\n                <div><label for=\"create_interface_type\" generated=\"true\" class=\"text-error\"></label></div>\n            </div>\n        </div>\n        <div id=\"config_inputbox\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"realm_embedded_bots"),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":58,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"input-group\">\n            <label for=\"bot_avatar_file_input\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":73},"end":{"line":61,"column":88}}}))
    + "</label>\n            <div id=\"bot_avatar_file\"></div>\n            <input type=\"file\" name=\"bot_avatar_file_input\" class=\"notvisible\" id=\"bot_avatar_file_input\" value=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":113},"end":{"line":63,"column":135}}}))
    + "\" />\n            <div id=\"add_bot_preview_text\">\n                <img id=\"add_bot_preview_image\" />\n            </div>\n            <button class=\"button white rounded small button-danger\" style=\"display: none;\" id=\"bot_avatar_clear_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":121},"end":{"line":67,"column":142}}}))
    + "</button>\n            <button class=\"button white rounded\" id=\"bot_avatar_upload_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":79},"end":{"line":68,"column":101}}}))
    + "</button> ("
    + alias2(lookupProperty(helpers,"t").call(alias1,"Optional",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":112},"end":{"line":68,"column":129}}}))
    + ")\n        </div>\n        <p>\n            <div id=\"bot_avatar_file_input_error\" class=\"text-error\"></div>\n        </p>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true,"useDepths":true});