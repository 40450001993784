var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>\n    <span class=\"stream\">"
    + alias3(alias2(alias1(depth0, "stream_name", {"start":{"line":2,"column":27},"end":{"line":2,"column":38}} ), depth0))
    + "</span> &gt; <span class=\"topic white-space-preserve-wrap "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":98},"end":{"line":2,"column":153}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":2,"column":157},"end":{"line":2,"column":175}} ), depth0))
    + "</span>\n</strong>\n";
},"useData":true});