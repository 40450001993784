var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user_profile_subscribe_widget\">\n    "
    + ((stack1 = container.invokePartial(require("./dropdown_widget.hbs"),undefined,{"name":"dropdown_widget","hash":{"widget_name":"user_profile_subscribe"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Subscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":140},"end":{"line":3,"column":155}}}),"intent":"brand","attention":"quiet","custom_classes":"add-subscription-button","label":lookupProperty(helpers,"t").call(alias1,"Subscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":54}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});