var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":11,"column":10}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "<span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_name", {"start":{"line":3,"column":38},"end":{"line":3,"column":48}} ), depth0))
    + ":</span>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"still_url"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<img src=\""
    + alias3(alias2(alias1(depth0, "still_url", {"start":{"line":5,"column":12},"end":{"line":5,"column":21}} ), depth0))
    + "\" class=\"emoji status-emoji status-emoji-name\" data-animated-url=\""
    + alias3(alias2(alias1(depth0, "url", {"start":{"line":5,"column":91},"end":{"line":5,"column":94}} ), depth0))
    + "\" data-still-url=\""
    + alias3(alias2(alias1(depth0, "still_url", {"start":{"line":5,"column":116},"end":{"line":5,"column":125}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(depth0, "emoji_name", {"start":{"line":5,"column":152},"end":{"line":5,"column":162}} ), depth0))
    + ":\" />";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<img src=\""
    + alias3(alias2(alias1(depth0, "url", {"start":{"line":8,"column":12},"end":{"line":8,"column":15}} ), depth0))
    + "\" class=\"emoji status-emoji status-emoji-name\" data-animated-url=\""
    + alias3(alias2(alias1(depth0, "url", {"start":{"line":8,"column":85},"end":{"line":8,"column":88}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(depth0, "emoji_name", {"start":{"line":8,"column":115},"end":{"line":8,"column":125}} ), depth0))
    + ":\" />";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_name"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<span class=\"emoji status-emoji status-emoji-name emoji-"
    + alias3(alias2(alias1(depth0, "emoji_code", {"start":{"line":10,"column":58},"end":{"line":10,"column":68}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(depth0, "emoji_name", {"start":{"line":10,"column":95},"end":{"line":10,"column":105}} ), depth0))
    + ":\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":10}}})) != null ? stack1 : "");
},"useData":true});