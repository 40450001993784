var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label class=\"preferences-radio-choice-label\">\n                    <div class=\"radio-choice-controls\">\n                        <input type=\"radio\" class=\"setting_user_list_style_choice\" name=\"user_list_style\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":13,"column":115},"end":{"line":13,"column":124}} ), depth0))
    + "\"/>\n                        <span class=\"preferences-radio-choice-text\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":14,"column":70},"end":{"line":14,"column":86}} ), depth0))
    + "</span>\n                    </div>\n                    <span class=\"right preview\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),1,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":30},"end":{"line":17,"column":46}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":22,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),2,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":46}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":31,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),3,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":32,"column":30},"end":{"line":32,"column":46}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </label>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                        <div class=\"user-name-and-status-emoji\">\n                            <span class=\"user-name\">"
    + container.escapeExpression(container.lambda(container.strict(depths[1], "full_name", {"start":{"line":19,"column":54},"end":{"line":19,"column":66}} ), depth0))
    + "</span>\n                            "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),undefined,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0","emoji_name":"house"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"user-name-and-status-text\">\n                            <div class=\"user-name-and-status-emoji\">\n                                <span class=\"user-name\">"
    + alias1(container.lambda(container.strict(depths[1], "full_name", {"start":{"line":26,"column":58},"end":{"line":26,"column":70}} ), depth0))
    + "</span>\n                                "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),undefined,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0","emoji_name":"house"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n                            <span class=\"status-text\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Working remotely",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":79}}}))
    + "</span>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"profile-with-avatar\">\n                            <div class=\"user-profile-picture\">\n                                <img src=\""
    + alias3(alias2(alias1(depths[1], "profile_picture", {"start":{"line":35,"column":44},"end":{"line":35,"column":62}} ), depth0))
    + "\"/>\n                            </div>\n                            <div class=\"user-name-and-status-wrapper\">\n                                <div class=\"user-name-and-status-emoji\">\n                                    <span class=\"user-name\">"
    + alias3(alias2(alias1(depths[1], "full_name", {"start":{"line":39,"column":62},"end":{"line":39,"column":74}} ), depth0))
    + "</span>\n                                    "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),undefined,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0","emoji_name":"house"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                                <span class=\"status-text\">"
    + alias3(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Working remotely",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":58},"end":{"line":42,"column":83}}}))
    + "</span>\n                            </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"information-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"subsection-header\">\n        <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Information",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":46}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":4,"column":97},"end":{"line":4,"column":121}}}),"section_name":"information-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"input-group\">\n        <label class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User list style",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":68}}}))
    + "</label>\n        <div class=\"user_list_style_values grey-box prop-element\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":9,"column":72},"end":{"line":9,"column":78}} ), depth0))
    + "user_list_style\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"user_list_style_values"),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"input-group thinner setting-next-is-related\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":53,"column":22},"end":{"line":53,"column":28}} ), depth0))
    + "web_animate_image_previews\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Play animated images",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":87},"end":{"line":53,"column":116}}}))
    + "</label>\n        <select name=\"web_animate_image_previews\" class=\"setting_web_animate_image_previews prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":54,"column":150},"end":{"line":54,"column":156}} ), depth0))
    + "web_animate_image_previews\" data-setting-widget-type=\"string\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_animate_image_previews_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":60,"column":22},"end":{"line":60,"column":28}} ), depth0))
    + "web_stream_unreads_count_display_policy\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show unread counts for",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":100},"end":{"line":60,"column":131}}}))
    + "</label>\n        <select name=\"web_stream_unreads_count_display_policy\" class=\"setting_web_stream_unreads_count_display_policy prop-element bootstrap-focus-style settings_select\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":61,"column":176},"end":{"line":61,"column":182}} ), depth0))
    + "web_stream_unreads_count_display_policy\"  data-setting-widget-type=\"number\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_stream_unreads_count_display_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"render_only":lookupProperty(lookupProperty(depth0,"settings_render_only"),"starred_message_counts"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"starred_message_counts"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"starred_message_counts"),"setting_name":"starred_message_counts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"render_only":lookupProperty(lookupProperty(depth0,"settings_render_only"),"receives_typing_notifications"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"receives_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"receives_typing_notifications"),"setting_name":"receives_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"render_only":lookupProperty(lookupProperty(depth0,"settings_render_only"),"hide_ai_features"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"hide_ai_features"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"hide_ai_features"),"setting_name":"hide_ai_features"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"render_only":lookupProperty(lookupProperty(depth0,"settings_render_only"),"fluid_layout_width"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"fluid_layout_width"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"fluid_layout_width"),"setting_name":"fluid_layout_width"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":95,"column":22},"end":{"line":95,"column":28}} ), depth0))
    + "demote_inactive_streams\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Demote inactive channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":84},"end":{"line":95,"column":117}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/manage-inactive-channels"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select name=\"demote_inactive_streams\" class=\"setting_demote_inactive_streams prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":98,"column":144},"end":{"line":98,"column":150}} ), depth0))
    + "demote_inactive_streams\"  data-setting-widget-type=\"number\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"demote_inactive_streams_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"render_only":lookupProperty(lookupProperty(depth0,"settings_render_only"),"high_contrast_mode"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"high_contrast_mode"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"high_contrast_mode"),"setting_name":"high_contrast_mode"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});