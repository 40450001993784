var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"info-density-button-container\">\n            <button class=\"info-density-button default-button\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"property"),"web_font_size_px",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":113}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":198}}})) != null ? stack1 : "")
    + "\">\n                <i class=\"zulip-icon "
    + container.escapeExpression(container.lambda(container.strict(depth0, "default_icon_class", {"start":{"line":5,"column":39},"end":{"line":5,"column":57}} ), depth0))
    + "\" aria-hidden=\"true\"></i>\n            </button>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set font size to default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":115},"end":{"line":4,"column":147}}}));
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set line spacing to default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":155},"end":{"line":4,"column":191}}}));
},"6":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"display-value\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "display_value", {"start":{"line":10,"column":38},"end":{"line":10,"column":51}} ), depth0))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Decrease font size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":112},"end":{"line":14,"column":138}}}));
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Decrease line spacing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":146},"end":{"line":14,"column":176}}}));
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Increase font size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":112},"end":{"line":26,"column":138}}}));
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Increase line spacing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":146},"end":{"line":26,"column":176}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"button-group\" data-property=\""
    + alias3(alias2(alias1(depth0, "property", {"start":{"line":1,"column":43},"end":{"line":1,"column":51}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"for_settings_ui"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"for_settings_ui"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    <input class=\"current-value prop-element\" id=\""
    + alias3(alias2(alias1(depth0, "prefix", {"start":{"line":12,"column":52},"end":{"line":12,"column":58}} ), depth0))
    + alias3(alias2(alias1(depth0, "property", {"start":{"line":12,"column":62},"end":{"line":12,"column":70}} ), depth0))
    + "\" data-setting-widget-type=\"info-density-setting\" type=\"hidden\" value=\""
    + alias3(alias2(alias1(depth0, "property_value", {"start":{"line":12,"column":145},"end":{"line":12,"column":159}} ), depth0))
    + "\" />\n    <div class=\"info-density-button-container\">\n        <button class=\"info-density-button decrease-button\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"property"),"web_font_size_px",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":14,"column":78},"end":{"line":14,"column":110}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":14,"column":72},"end":{"line":14,"column":183}}})) != null ? stack1 : "")
    + "\">\n            <i class=\"zulip-icon zulip-icon-minus\" aria-hidden=\"true\"></i>\n        </button>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"for_settings_ui"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"info-density-button-container\">\n        <button class=\"info-density-button increase-button\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"property"),"web_font_size_px",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":26,"column":78},"end":{"line":26,"column":110}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":26,"column":72},"end":{"line":26,"column":183}}})) != null ? stack1 : "")
    + "\">\n            <i class=\"zulip-icon zulip-icon-plus\" aria-hidden=\"true\"></i>\n        </button>\n    </div>\n</div>\n";
},"useData":true});