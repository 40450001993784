var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"recent_view_filter_buttons\" role=\"group\">\n    <div id=\"recent_filters_group\">\n        "
    + ((stack1 = container.invokePartial(require("./recent_view_filters.hbs"),depth0,{"name":"recent_view_filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"search_group\" role=\"group\">\n        <input type=\"text\" id=\"recent_view_search\" class=\"filter_text_input\" value=\""
    + alias1(container.lambda(container.strict(depth0, "search_val", {"start":{"line":6,"column":87},"end":{"line":6,"column":97}} ), depth0))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Filter topics (t)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":134},"end":{"line":6,"column":160}}}))
    + "\" />\n        <button type=\"button\" class=\"clear_search_button\" id=\"recent_view_search_clear\">\n            <i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i>\n        </button>\n    </div>\n</div>\n<div class=\"table_fix_head\">\n    <div class=\"recent-view-container\">\n        <table class=\"table table-responsive\">\n            <thead id=\"recent-view-table-headers\">\n                <tr>\n                    <th class=\"recent-view-stream-header\" data-sort=\"stream_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":82},"end":{"line":17,"column":98}}}))
    + "\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                    <th class=\"recent-view-topic-header\" data-sort=\"topic_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":80},"end":{"line":20,"column":94}}}))
    + "\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                    <th data-sort=\"unread_sort\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Sort by unread message count",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":68},"end":{"line":23,"column":105}}}))
    + "\" class=\"recent-view-unread-header unread_sort tippy-zulip-delayed-tooltip hidden-for-spectators\">\n                        <i class=\"zulip-icon zulip-icon-unread\"></i>\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                    <th class='recent-view-participants-header participants_header'>"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Participants",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":84},"end":{"line":27,"column":105}}}))
    + "</th>\n                    <th data-sort=\"numeric\" data-sort-prop=\"last_msg_id\" class=\"recent-view-last-msg-time-header last_msg_time_header active descend\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":150},"end":{"line":28,"column":163}}}))
    + "\n                        <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                    </th>\n                </tr>\n            </thead>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});