var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-showcase\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_realm_emoji"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"emoji-canonical-name\" title=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":47},"end":{"line":8,"column":51}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":57},"end":{"line":8,"column":61}} ), depth0))
    + "</div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "    <img src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "url", {"start":{"line":4,"column":16},"end":{"line":4,"column":19}} ), depth0))
    + "\" class=\"emoji emoji-preview\"/>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"emoji emoji-preview emoji-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_code", {"start":{"line":6,"column":44},"end":{"line":6,"column":54}} ), depth0))
    + "\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_dict"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":9}}})) != null ? stack1 : "");
},"useData":true});