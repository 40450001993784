var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"list-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_item_selected"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":62}}})) != null ? stack1 : "")
    + "\" role=\"presentation\" data-unique-id=\""
    + alias4(alias3(alias2(depth0, "unique_id", {"start":{"line":2,"column":102},"end":{"line":2,"column":111}} ), depth0))
    + "\" data-name=\""
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":2,"column":128},"end":{"line":2,"column":132}} ), depth0))
    + "\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"description"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"dropdown-list-item-common-styles\">\n            <span class=\"dropdown-list-item-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bold_current_selection"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n            <span class=\"dropdown-list-item-description line-clamp\">\n                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "description", {"start":{"line":19,"column":18},"end":{"line":19,"column":29}} ), depth0))
    + "\n            </span>\n        </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"dropdown-list-bold-selected\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":7,"column":64},"end":{"line":7,"column":68}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_edit_icon"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":10,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_delete_icon"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = container.invokePartial(require("./components/icon_button.hbs"),undefined,{"name":"components/icon_button","hash":{"aria-label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit snippet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":127},"end":{"line":9,"column":145}}}),"icon":"edit","intent":"neutral","custom_classes":"dropdown-list-edit"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = container.invokePartial(require("./components/icon_button.hbs"),undefined,{"name":"components/icon_button","hash":{"aria-label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete snippet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":129},"end":{"line":12,"column":149}}}),"icon":"trash","intent":"danger","custom_classes":"dropdown-list-delete"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":15,"column":22},"end":{"line":15,"column":26}} ), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"dropdown-list-item-common-styles\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"stream"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./inline_decorated_stream_name.hbs"),undefined,{"name":"inline_decorated_stream_name","hash":{"show_colored_icon":true,"stream":lookupProperty(depth0,"stream")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_direct_message"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":36,"column":12}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"zulip-icon zulip-icon-users stream-privacy-type-icon\"></i> "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":27,"column":87},"end":{"line":27,"column":91}} ), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_setting_disabled"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":36,"column":12}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"setting-disabled-option\"><i class=\"setting-disabled-option-icon fa fa-ban\" aria-hidden=\"true\"></i>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":127},"end":{"line":29,"column":143}}}))
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bold_current_selection"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "            ";
},"22":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"dropdown-list-bold-selected\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":32,"column":64},"end":{"line":32,"column":68}} ), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"item"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});