var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":2,"column":22},"end":{"line":2,"column":31}} ), depth0))
    + "\" data-stream=\""
    + alias3(alias2(alias1(depth0, "stream", {"start":{"line":2,"column":50},"end":{"line":2,"column":56}} ), depth0))
    + "\" data-topic=\""
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":2,"column":74},"end":{"line":2,"column":79}} ), depth0))
    + "\" data-date-updated=\""
    + alias3(alias2(alias1(depth0, "date_updated_str", {"start":{"line":2,"column":104},"end":{"line":2,"column":120}} ), depth0))
    + "\" data-visibility-policy=\""
    + alias3(alias2(alias1(depth0, "visibility_policy", {"start":{"line":2,"column":150},"end":{"line":2,"column":167}} ), depth0))
    + "\">\n    <td class=\"user-topic-stream\">"
    + alias3(alias2(alias1(depth0, "stream", {"start":{"line":3,"column":36},"end":{"line":3,"column":42}} ), depth0))
    + "</td>\n    <td class=\"white-space-preserve-wrap user-topic "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depths[1],"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(alias1(depths[1], "topic_display_name", {"start":{"line":4,"column":114},"end":{"line":4,"column":135}} ), depth0))
    + "</td>\n    <td>\n        <select class=\"settings_user_topic_visibility_policy list_select bootstrap-focus-style\" data-setting-widget-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depths[1],"user_topic_visibility_policy_values"),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td class=\"topic_date_updated\">"
    + alias3(alias2(alias1(depth0, "date_updated_str", {"start":{"line":12,"column":37},"end":{"line":12,"column":53}} ), depth0))
    + "</td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value='"
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":8,"column":33},"end":{"line":8,"column":42}} ), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),lookupProperty(depths[1],"visibility_policy"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":87}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":8,"column":107},"end":{"line":8,"column":123}} ), depth0))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"user_topic"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});