var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "type", {"start":{"line":1,"column":28},"end":{"line":1,"column":32}} ), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "button";
},"5":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":1,"column":73},"end":{"line":1,"column":75}} ), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "custom_classes", {"start":{"line":1,"column":117},"end":{"line":1,"column":131}} ), depth0))
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    return "hide";
},"11":function(container,depth0,helpers,partials,data) {
    return "data-tippy-content=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "data-tippy-content", {"start":{"line":1,"column":269},"end":{"line":1,"column":287}} ), depth0))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "data-tooltip-template-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "data-tooltip-template-id", {"start":{"line":1,"column":358},"end":{"line":1,"column":382}} ), depth0))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return "aria-label=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "aria-label", {"start":{"line":1,"column":438},"end":{"line":1,"column":448}} ), depth0))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"19":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"zulip-icon zulip-icon-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "icon", {"start":{"line":5,"column":38},"end":{"line":5,"column":42}} ), depth0))
    + "\" aria-hidden=\"true\"></i>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"action-button-label\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "label", {"start":{"line":8,"column":40},"end":{"line":8,"column":45}} ), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"type"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":14},"end":{"line":1,"column":55}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"id"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + " class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"custom_classes"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":93},"end":{"line":1,"column":141}}})) != null ? stack1 : "")
    + "action-button action-button-"
    + alias4(alias3(alias2(depth0, "attention", {"start":{"line":1,"column":171},"end":{"line":1,"column":180}} ), depth0))
    + "-"
    + alias4(alias3(alias2(depth0, "intent", {"start":{"line":1,"column":185},"end":{"line":1,"column":191}} ), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hidden"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":194},"end":{"line":1,"column":219}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"data-tippy-content"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":221},"end":{"line":1,"column":297}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"data-tooltip-template-id"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":298},"end":{"line":1,"column":392}}})) != null ? stack1 : "")
    + " tabindex=\"0\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"aria-label"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":406},"end":{"line":1,"column":458}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"disabled"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":33}}})) != null ? stack1 : "")
    + "\n  >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"icon"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"label"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "</button>\n";
},"useData":true});