var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"attachments-settings\" class=\"settings-section\" data-name=\"uploaded-files\">\n    <div id=\"attachment-stats-holder\"></div>\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your uploads",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":32}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter uploads",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":90},"end":{"line":5,"column":110}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":78}}}),"id":"upload_file_search"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"clear-float\"></div>\n    <div class=\"alert\" id=\"delete-upload-status\"></div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"name\" class=\"upload-file-name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"File",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":90},"end":{"line":12,"column":103}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"active upload-date\" data-sort=\"numeric\" data-sort-prop=\"create_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date uploaded",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":96},"end":{"line":15,"column":118}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"upload-mentioned-in\" data-sort=\"mentioned_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentioned in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":73},"end":{"line":18,"column":94}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"upload-size\" data-sort=\"numeric\" data-sort-prop=\"size\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":82},"end":{"line":21,"column":95}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"upload-actions actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":67}}}))
    + "</th>\n            </thead>\n            <tbody data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not uploaded any files.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":31},"end":{"line":26,"column":72}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No uploaded files match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":101},"end":{"line":26,"column":154}}}))
    + "\" id=\"uploaded_files_table\"></tbody>\n        </table>\n    </div>\n    <div id=\"attachments_loading_indicator\"></div>\n</div>\n";
},"usePartial":true,"useData":true});