var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "data-sort=\"email\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-deactivated-users-list\" class=\"user-settings-section\" data-user-settings-section=\"deactivated\">\n    <div class=\"clear-float\"></div>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivated users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":38}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/deactivate-or-reactivate-a-user"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </h3>\n        <div class=\"alert-notification\" id=\"deactivated-user-field-status\"></div>\n        <div class=\"user_filters\">\n            "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":lookupProperty(depth0,"deactivated_user_list_dropdown_widget_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter deactivated users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":70},"end":{"line":11,"column":100}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":58}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":85},"end":{"line":18,"column":98}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n                <th class=\"settings-email-column\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"allow_sorting_deactivated_users_list_by_email"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":50},"end":{"line":21,"column":127}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":128},"end":{"line":21,"column":142}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"allow_sorting_deactivated_users_list_by_email"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":27}}})) != null ? stack1 : "")
    + "                </th>\n                <th class=\"user_role\" data-sort=\"role\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":55},"end":{"line":26,"column":68}}}))
    + "\n                    <i class=\"table-sortable-arrow zulip-icon zulip-icon-sort-arrow-down\"></i>\n                </th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_deactivated_users_table\" class=\"admin_user_table\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no deactivated users.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":66}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No users match your filters.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":95},"end":{"line":34,"column":132}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_deactivated_users_loading_indicator\"></div>\n</div>\n";
},"usePartial":true,"useData":true});