var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"name"),"prefer-html",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":42}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"input-group\" id=\""
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":12,"column":39},"end":{"line":12,"column":48}} ), depth0))
    + "-input-group\">\n            <label class=\"checkbox\">\n                <input class=\"tag-checkbox\" id=\""
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":14,"column":51},"end":{"line":14,"column":60}} ), depth0))
    + "\" type=\"checkbox\"/>\n                <span class=\"rendered-checkbox\"></span>\n            </label>\n            <label class=\"inline\" for=\""
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":17,"column":41},"end":{"line":17,"column":50}} ), depth0))
    + "\">"
    + ((stack1 = alias3(alias2(depth0, "description", {"start":{"line":17,"column":57},"end":{"line":17,"column":73}} ), depth0)) != null ? stack1 : "")
    + "</label>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <hr />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"copy-email-modal\">\n    "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),undefined,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who should be the sender of the Zulip messages for this email address?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":88}}}),"widget_name":"sender_channel_email_address"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <p class=\"question-which-parts\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Which parts of the emails should be included in the Zulip messages?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":83}}}))
    + "\n    </p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"tags"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":19,"column":13}}})) != null ? stack1 : "")
    + "    <hr />\n    <p class=\"stream-email-header\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel email address:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":38}}}))
    + "\n    </p>\n    <div class=\"stream-email\">\n        <div class=\"email-address\">"
    + alias2(alias4(alias3(depth0, "email_address", {"start":{"line":25,"column":37},"end":{"line":25,"column":50}} ), depth0))
    + "</div>\n        <span class=\"copy-button tippy-zulip-tooltip copy-email-address\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":93},"end":{"line":26,"column":120}}}))
    + "\" data-clipboard-text=\""
    + alias2(alias4(alias3(depth0, "email_address", {"start":{"line":26,"column":145},"end":{"line":26,"column":158}} ), depth0))
    + "\">\n            <i class=\"zulip-icon zulip-icon-copy\"></i>\n        </span>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});