var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<form class=\"preferences-settings-form\">\n    "
    + ((stack1 = container.invokePartial(require("./preferences_general.hbs"),depth0,{"name":"preferences_general","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./preferences_emoji.hbs"),depth0,{"name":"preferences_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./preferences_navigation.hbs"),depth0,{"name":"preferences_navigation","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./preferences_information.hbs"),depth0,{"name":"preferences_information","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</form>\n";
},"usePartial":true,"useData":true});