var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "-";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pill"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"deactivated"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":67}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":7,"column":85},"end":{"line":7,"column":97}} ), depth0))
    + "\">\n            <img class=\"pill-image\" src=\""
    + alias4(alias3(alias2(depth0, "img_src", {"start":{"line":8,"column":43},"end":{"line":8,"column":55}} ), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"deactivated"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"pill-label\">\n                <span class=\"pill-value\">"
    + alias4(alias3(alias2(depth0, "full_name", {"start":{"line":13,"column":44},"end":{"line":13,"column":58}} ), depth0))
    + "</span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_add_guest_user_indicator"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":98}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":25}}})) != null ? stack1 : "")
    + "</span>\n            <div class=\"exit\">\n                <a role=\"button\" class=\"zulip-icon zulip-icon-close pill-close-button\"></a>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " deactivated-pill";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"fa fa-ban slashed-circle-icon\"></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;<i>("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":71},"end":{"line":14,"column":84}}}))
    + ")</i>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-pill-container pill\" tabindex=0>\n    <span class=\"pill-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"negated"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":39}}})) != null ? stack1 : "")
    + container.escapeExpression(container.lambda(container.strict(depth0, "operator", {"start":{"line":4,"column":11},"end":{"line":4,"column":19}} ), depth0))
    + ":\n    </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"users"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});