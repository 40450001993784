var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            This will clear the <z-field-name></z-field-name> profile field for 1 user.\n            \n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<strong>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "field_name", {"start":{"line":5,"column":49},"end":{"line":5,"column":59}} ), depth0))
    + "</strong>";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            This will clear the <z-field-name></z-field-name> profile field for <z-count></z-count> users.\n            \n            \n";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "count", {"start":{"line":10,"column":36},"end":{"line":10,"column":41}} ), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted option:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":36}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted options:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":37}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"count"),1,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":22}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "</p>\n<p>\n    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"deleted_options_count"),1,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":42}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"deleted_values"),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</p>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-field-name"],"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":81}}}) || fn;
  return fn;
  }

,"useDecorators":true,"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-count"],"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":54}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-field-name"],"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":81}}}) || fn;
  return fn;
  }

,"useData":true,"useDepths":true});