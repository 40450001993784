var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navigation-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"subsection-header\">\n        <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Navigation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":45}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":120}}}),"section_name":"navigation-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"input-group thinner setting-next-is-related\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":8,"column":22},"end":{"line":8,"column":28}} ), depth0))
    + "web_home_view\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Home view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":74},"end":{"line":8,"column":92}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/configure-home-view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select name=\"web_home_view\" class=\"setting_web_home_view prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":11,"column":124},"end":{"line":11,"column":130}} ), depth0))
    + "web_home_view\" data-setting-widget-type=\"string\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_home_view_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"web_escape_navigates_to_home_view"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"web_escape_navigates_to_home_view"),"setting_name":"web_escape_navigates_to_home_view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"web_navigate_to_sent_message"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"web_navigate_to_sent_message"),"setting_name":"web_navigate_to_sent_message"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":29,"column":22},"end":{"line":29,"column":28}} ), depth0))
    + "web_mark_read_on_scroll_policy\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automatically mark messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":91},"end":{"line":29,"column":135}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/marking-messages-as-read"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select name=\"web_mark_read_on_scroll_policy\" class=\"setting_web_mark_read_on_scroll_policy prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":32,"column":158},"end":{"line":32,"column":164}} ), depth0))
    + "web_mark_read_on_scroll_policy\"  data-setting-widget-type=\"number\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_mark_read_on_scroll_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":38,"column":22},"end":{"line":38,"column":28}} ), depth0))
    + "web_channel_default_view\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel links in the left sidebar go to",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":85},"end":{"line":38,"column":133}}}))
    + "</label>\n        <select name=\"web_channel_default_view\" class=\"setting_web_channel_default_view prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":39,"column":146},"end":{"line":39,"column":152}} ), depth0))
    + "web_channel_default_view\"  data-setting-widget-type=\"number\">\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_channel_default_view_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </select>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});