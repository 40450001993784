var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"linkifier_row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":61}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":82}}})) != null ? stack1 : "")
    + "\" data-linkifier-id=\""
    + alias4(alias3(alias2(depth0, "id", {"start":{"line":2,"column":105},"end":{"line":2,"column":107}} ), depth0))
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":45}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"linkifier_pattern\">"
    + alias4(alias3(alias2(depth0, "pattern", {"start":{"line":10,"column":42},"end":{"line":10,"column":49}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"linkifier_url_template\">"
    + alias4(alias3(alias2(depth0, "url_template", {"start":{"line":13,"column":47},"end":{"line":13,"column":59}} ), depth0))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " movable-row";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"move-handle\">\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n            </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        <button class=\"button small rounded edit button-warning tippy-zulip-delayed-tooltip\" data-linkifier-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":17,"column":114},"end":{"line":17,"column":116}} ), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":140},"end":{"line":17,"column":153}}}))
    + "\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":167},"end":{"line":17,"column":180}}}))
    + "\">\n            <i class=\"fa fa-pencil\"></i>\n        </button>\n        <button class=\"button small rounded delete button-danger tippy-zulip-delayed-tooltip\" data-linkifier-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":20,"column":115},"end":{"line":20,"column":117}} ), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":141},"end":{"line":20,"column":156}}}))
    + "\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":170},"end":{"line":20,"column":185}}}))
    + "\">\n            <i class=\"fa fa-trash-o\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"linkifier"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});