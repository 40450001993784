var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tippy-zulip-delayed-tooltip selected-stream-title\" data-tooltip-template-id=\"view-stream-tooltip-template\" data-tippy-placement=\"top\" href=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "preview_url", {"start":{"line":1,"column":171},"end":{"line":1,"column":182}} ), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add subscribers to ",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":50}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"preview_url"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":191}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_url"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":61}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./stream_privacy_icon.hbs"),undefined,{"name":"stream_privacy_icon","hash":{"is_archived":lookupProperty(lookupProperty(depth0,"sub"),"is_archived"),"is_web_public":lookupProperty(lookupProperty(depth0,"sub"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"sub"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<span class=\"stream-name-title\">"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"sub"), "name", {"start":{"line":7,"column":34},"end":{"line":7,"column":42}} ), depth0))
    + "</span>\n</a>\n";
},"usePartial":true,"useData":true});