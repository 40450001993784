var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("../settings/settings_checkbox.hbs"),undefined,{"name":"../settings/settings_checkbox","hash":{"tooltip_message":lookupProperty(depth0,"tooltip_message"),"is_disabled":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_edit"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":40}}}),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"setting_labels"),lookupProperty(depth0,"setting_name"),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":59}}}),"is_checked":true,"prefix":lookupProperty(depths[1],"id_prefix"),"setting_name":lookupProperty(depth0,"setting_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-subsection-parent\" data-group-id=\""
    + alias3(alias2(alias1(depth0, "group_id", {"start":{"line":1,"column":57},"end":{"line":1,"column":65}} ), depth0))
    + "\">\n    <div class=\"subsection-header\">\n        <h3>"
    + alias3(alias2(alias1(depth0, "group_name", {"start":{"line":3,"column":14},"end":{"line":3,"column":24}} ), depth0))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),undefined,{"name":"../settings/settings_save_discard_widget","hash":{"show_only_indicator":false},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"subsection-settings\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"assigned_permissions"),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});