var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <li>\n                <button data-idx=\""
    + alias3(alias2(alias1(depth0, "idx", {"start":{"line":6,"column":37},"end":{"line":6,"column":45}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "short_name", {"start":{"line":6,"column":53},"end":{"line":6,"column":68}} ), depth0))
    + "</button>\n                &nbsp;\n                "
    + alias3(alias2(alias1(depth0, "long_name", {"start":{"line":8,"column":19},"end":{"line":8,"column":33}} ), depth0))
    + "\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget-choices\">\n    <div class=\"widget-choices-heading\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "heading", {"start":{"line":2,"column":43},"end":{"line":2,"column":50}} ), depth0))
    + "</div>\n    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"choices"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});