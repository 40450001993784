var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hide";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <button class=\"button rounded sticky-bottom-option\">\n        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "sticky_bottom_option", {"start":{"line":13,"column":10},"end":{"line":13,"column":30}} ), depth0))
    + "\n    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dropdown-list-container "
    + alias1(container.lambda(container.strict(depth0, "widget_name", {"start":{"line":1,"column":38},"end":{"line":1,"column":49}} ), depth0))
    + "-dropdown-list-container\">\n    <div class=\"dropdown-list-search popover-filter-input-wrapper\">\n        <input class=\"dropdown-list-search-input popover-filter-input filter_text_input"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"hide_search_box"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":87},"end":{"line":3,"column":122}}})) != null ? stack1 : "")
    + "\" type=\"text\" placeholder=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":149},"end":{"line":3,"column":164}}}))
    + "\" autofocus/>\n    </div>\n    <div class=\"dropdown-list-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <ul class=\"dropdown-list\"></ul>\n    </div>\n    <div class=\"no-dropdown-items dropdown-list-item-common-styles\">\n        "
    + alias1(lookupProperty(helpers,"t").call(alias2,"No matching results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":35}}}))
    + "\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"sticky_bottom_option"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});