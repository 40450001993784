var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"buddy-list-user-link view-all-subscribers-link\">\n    <a class=\"right-sidebar-wrappable-text-container\" href=\""
    + alias1(container.lambda(container.strict(depth0, "stream_edit_hash", {"start":{"line":2,"column":62},"end":{"line":2,"column":78}} ), depth0))
    + "\">\n        <span class=\"right-sidebar-wrappable-text-inner\">\n            "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View all subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":41}}}))
    + "\n        </span>\n    </a>\n</div>\n";
},"useData":true});