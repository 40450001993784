var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <p class=\"banner_content\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "banner_text", {"start":{"line":4,"column":36},"end":{"line":4,"column":47}} ), depth0))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"banner_content\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"button_link"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + alias3(alias2(alias1(depth0, "button_link", {"start":{"line":10,"column":19},"end":{"line":10,"column":30}} ), depth0))
    + "\">\n            <button class=\"main-view-banner-action-button"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"hide_close_button"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":100}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(alias1(depth0, "button_text", {"start":{"line":11,"column":104},"end":{"line":11,"column":115}} ), depth0))
    + "</button>\n        </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " right_edge";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"main-view-banner-action-button"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"hide_close_button"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":96}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "button_text", {"start":{"line":14,"column":100},"end":{"line":14,"column":111}} ), depth0))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"main-view-banner "
    + alias3(alias2(alias1(depth0, "banner_type", {"start":{"line":1,"column":31},"end":{"line":1,"column":42}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "classname", {"start":{"line":1,"column":47},"end":{"line":1,"column":56}} ), depth0))
    + "\">\n    <div class=\"main-view-banner-elements-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"banner_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"button_text"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"hide_close_button"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});