var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"message-edit-message-row overlay-message-row\" data-message-edit-history-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "timestamp", {"start":{"line":4,"column":94},"end":{"line":4,"column":103}} ), depth0))
    + "\">\n        <div class=\"message-edit-message-info-box overlay-message-info-box\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"message_row"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_stream"),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":35},"end":{"line":27,"column":83}}})) != null ? stack1 : "")
    + "\" role=\"listitem\">\n                <div class=\"messagebox\">\n                    <div class=\"messagebox-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"initial_entry_for_move_history"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":58,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message_header message_header_stream\">\n                <div class=\"message-header-contents\" style=\"background: "
    + alias3(alias2(alias1(depth0, "recipient_bar_color", {"start":{"line":8,"column":74},"end":{"line":8,"column":93}} ), depth0))
    + ";\">\n                    <div class=\"message_label_clickable stream_label\">\n                        <span class=\"private_message_header_name\">"
    + alias3(alias2(alias1(depth0, "edited_by_notice", {"start":{"line":10,"column":69},"end":{"line":10,"column":85}} ), depth0))
    + "</span>\n                    </div>\n                    <div class=\"recipient_row_date\" title=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Last modified",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":59},"end":{"line":12,"column":80}}}))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"{edited_at_time}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":82},"end":{"line":12,"column":107}}}))
    + "\n\n                    </div>\n                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message_header message_header_private_message\">\n                <div class=\"message-header-contents\">\n                    <div class=\"message_label_clickable stream_label\">\n                        <span class=\"private_message_header_name\">"
    + alias1(container.lambda(container.strict(depth0, "edited_by_notice", {"start":{"line":21,"column":69},"end":{"line":21,"column":85}} ), depth0))
    + "</span>\n                    </div>\n                    <div class=\"recipient_row_date\" title=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Last modified",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":59},"end":{"line":23,"column":80}}}))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"{edited_at_time}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":82},"end":{"line":23,"column":107}}}))
    + "</div>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"message_content message_edit_history_content\">\n                            <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":31},"end":{"line":32,"column":47}}}))
    + ": <span class=\"highlight_text_inserted\">"
    + alias2(alias4(alias3(depth0, "new_stream", {"start":{"line":32,"column":90},"end":{"line":32,"column":100}} ), depth0))
    + "</span></p>\n                            <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":45}}}))
    + ":\n                                <span class=\"highlight_text_inserted "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_empty_string_new_topic"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":69},"end":{"line":34,"column":128}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias4(alias3(depth0, "new_topic_display_name", {"start":{"line":34,"column":133},"end":{"line":34,"column":155}} ), depth0))
    + "</span>\n                            </p>\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"stream_changed"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":44,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"topic_edited"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":52,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"body_to_render"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":57,"column":31}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.strict, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"message_content message_edit_history_content\">\n                            <p>"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":31},"end":{"line":40,"column":47}}}))
    + ": <span class=\"highlight_text_inserted\">"
    + alias1(alias3(alias2(depth0, "new_stream", {"start":{"line":40,"column":90},"end":{"line":40,"column":100}} ), depth0))
    + "</span>\n                                <span class=\"highlight_text_deleted\">"
    + alias1(alias3(alias2(depth0, "prev_stream", {"start":{"line":41,"column":72},"end":{"line":41,"column":83}} ), depth0))
    + "</span>\n                            </p>\n                        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"message_content message_edit_history_content\">\n                            <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":31},"end":{"line":47,"column":45}}}))
    + ":\n                                <span class=\"highlight_text_inserted "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_empty_string_new_topic"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":69},"end":{"line":48,"column":128}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias4(alias3(depth0, "new_topic_display_name", {"start":{"line":48,"column":133},"end":{"line":48,"column":155}} ), depth0))
    + "</span>\n                                <span class=\"highlight_text_deleted "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_empty_string_prev_topic"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":68},"end":{"line":49,"column":128}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias4(alias3(depth0, "prev_topic_display_name", {"start":{"line":49,"column":133},"end":{"line":49,"column":156}} ), depth0))
    + "</span>\n                            </p>\n                        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"message_content rendered_markdown message_edit_history_content\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"body_to_render"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":55,"column":65}}}))
    + "\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"edited_messages"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":64,"column":9}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});