var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-stripped\" id=\"realm_domains_table\">\n    <thead>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Domain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":27}}}))
    + "</th>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Allow subdomains",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":36}}}))
    + "</th>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":27}}}))
    + "</th>\n    </thead>\n    <tbody>\n    </tbody>\n    <tfoot>\n        <tr id=\"add-realm-domain-widget\">\n            <td><input type=\"text\" class=\"new-realm-domain modal_text_input\" placeholder=\"acme.com\" /></td>\n            <td>\n                <label class=\"checkbox\">\n                    <input type=\"checkbox\" class=\"new-realm-domain-allow-subdomains\" />\n                    <span class=\"rendered-checkbox\"></span>\n                </label>\n            </td>\n            <td>\n                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"brand","attention":"quiet","id":"submit-add-realm-domain","label":lookupProperty(helpers,"t").call(alias1,"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":33}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n    </tfoot>\n</table>\n<div class=\"alert realm_domains_info\"></div>\n";
},"usePartial":true,"useData":true});