var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"inline-topic-edit-placeholder placeholder\">\n            "
    + ((stack1 = container.invokePartial(require("./topic_not_mandatory_placeholder_text.hbs"),undefined,{"name":"topic_not_mandatory_placeholder_text","hash":{"empty_string_topic_display_name":lookupProperty(depth0,"empty_string_topic_display_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form class=\"topic_edit_form\">\n    <span class=\"topic_value_mirror hide\"></span>\n    <input type=\"text\" value=\"\" autocomplete=\"off\" maxlength=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "max_topic_length", {"start":{"line":5,"column":65},"end":{"line":5,"column":81}} ), depth0))
    + "\" class=\"inline_topic_edit header-v\"/>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_mandatory_topics"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"data-tooltip-template-id":"save-button-tooltip-template","intent":"neutral","attention":"quiet","icon":"check","custom_classes":"topic_edit_save tippy-zulip-delayed-tooltip"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./components/action_button.hbs"),undefined,{"name":"components/action_button","hash":{"data-tooltip-template-id":"cancel-button-tooltip-template","intent":"neutral","attention":"borderless","icon":"circle-x","custom_classes":"topic_edit_cancel tippy-zulip-delayed-tooltip"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"topic_edit_spinner\"></div>\n</form>\n";
},"usePartial":true,"useData":true});