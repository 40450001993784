var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"create_stream_button create_stream_plus_button tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"create-new-stream-tooltip-template\" data-tippy-placement=\"bottom\">\n                            <i class=\"create_button_plus_sign zulip-icon zulip-icon-square-plus\" aria-hidden=\"true\"></i>\n                        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide_filter";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"#channels/all\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View all channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":52},"end":{"line":37,"column":77}}}))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a href=\"#channels/new\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Create a channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":56},"end":{"line":56,"column":80}}}))
    + "</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"settings-empty-option-text\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You do not have permission to create channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":28},"end":{"line":74,"column":83}}}))
    + "\n                        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"subscription_overlay\" class=\"overlay\" data-overlay=\"subscriptions\">\n    <div class=\"flex overlay-content\">\n        <div class=\"subscriptions-container overlay-container\">\n            <div class=\"subscriptions-header\">\n                <div class=\"fa fa-chevron-left\"></div>\n                <span class=\"subscriptions-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":67}}}))
    + "</span>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"left\">\n                <div class=\"list-toggler-container\">\n                    <div id=\"add_new_subscription\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_streams"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                        <div class=\"float-clear\"></div>\n                    </div>\n                </div>\n                <div class=\"input-append stream_name_search_section\" id=\"stream_filter\">\n                    <input type=\"text\" name=\"stream_name\" id=\"search_stream_name\" class=\"filter_text_input\" autocomplete=\"off\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":50}}}))
    + "\" value=\"\"/>\n                    <button type=\"button\" class=\"clear_search_button\" id=\"clear_search_stream_name\">\n                        <i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i>\n                    </button>\n                    <div class=\"stream_settings_filter_container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_has_archived_channels"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":126}}})) != null ? stack1 : "")
    + "\">\n                        "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":"stream_settings_filter"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"no-streams-to-show\">\n                    <div class=\"subscribed_streams_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are not subscribed to any channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":75}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_view_all_streams"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":38,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                    <div class=\"not_subscribed_streams_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No channels to show.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":56}}}))
    + "\n                            <a href=\"#channels/all\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View all channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":52},"end":{"line":44,"column":77}}}))
    + "</a>\n                        </span>\n                    </div>\n                    <div class=\"no_stream_match_filter_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No channels match your filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":49,"column":66}}}))
    + "\n                        </span>\n                    </div>\n                    <div class=\"all_streams_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no channels you can view in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":92}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_streams"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":57,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </div>\n                <div class=\"streams-list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                </div>\n            </div>\n            <div class=\"right\">\n                <div class=\"display-type\">\n                    <div id=\"stream_settings_title\" class=\"stream-info-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":78},"end":{"line":66,"column":103}}}))
    + "</div>\n                </div>\n                <div class=\"nothing-selected\">\n                    <div class=\"stream-info-banner\"></div>\n                    <div class=\"create-stream-button-container\">\n                        <button type=\"button\" class=\"create_stream_button animated-purple-button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_streams"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":98},"end":{"line":71,"column":147}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":148},"end":{"line":71,"column":171}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_streams"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":24},"end":{"line":76,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div id=\"stream_settings\" class=\"settings\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./stream_creation_form.hbs"),depth0,{"name":"stream_creation_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});