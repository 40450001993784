var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Learn where to find everything you need to get started with this 2-minute video tour.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":96}}}))
    + "</p>\n<div id=\"navigation-tour-video-wrapper\">\n    <video id=\"navigation-tour-video\" controls poster=\""
    + alias2(alias4(alias3(depth0, "poster_src", {"start":{"line":3,"column":57},"end":{"line":3,"column":67}} ), depth0))
    + "\">\n        <source src=\""
    + alias2(alias4(alias3(depth0, "video_src", {"start":{"line":4,"column":23},"end":{"line":4,"column":32}} ), depth0))
    + "\" type=\"video/mp4\"/>\n    </video>\n    <div id=\"navigation-tour-video-ended-button-wrapper\">\n        <button id=\"navigation-tour-video-ended-button\" class=\"action-button-primary-brand\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Let's go!",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":92},"end":{"line":7,"column":109}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});