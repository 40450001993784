var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-profile\" data-name=\"organization-profile\" class=\"settings-section\">\n    <form class=\"admin-realm-form org-profile-form\">\n        <div class=\"alert\" id=\"admin-realm-deactivation-status\"></div>\n\n        <div id=\"org-org-profile\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":49}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/create-your-organization-profile"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"org-profile"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"organization-settings-parent\">\n                <div class=\"input-group admin-realm\">\n                    <label for=\"id_realm_name\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":76},"end":{"line":15,"column":102}}}))
    + "</label>\n                    <input type=\"text\" id=\"id_realm_name\" name=\"realm_name\" class=\"admin-realm-name setting-widget prop-element settings_text_input\"\n                      autocomplete=\"off\" data-setting-widget-type=\"string\"\n                      value=\""
    + alias2(alias4(alias3(depth0, "realm_name", {"start":{"line":18,"column":32},"end":{"line":18,"column":42}} ), depth0))
    + "\" maxlength=\"40\" />\n                </div>\n                <div class=\"input-group admin-realm\">\n                    <label for=\"id_realm_org_type\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":80},"end":{"line":21,"column":106}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/organization-type"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_org_type\" class=\"setting-widget prop-element settings_select bootstrap-focus-style\" id=\"id_realm_org_type\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"realm_org_type_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"help_link":"/help/communities-directory","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_want_advertise_in_communities_directory"),"is_disabled":lookupProperty(depth0,"disable_want_advertise_in_communities_directory"),"is_checked":lookupProperty(depth0,"realm_want_advertise_in_communities_directory"),"prefix":"id_","setting_name":"realm_want_advertise_in_communities_directory"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"input-group admin-realm\">\n                    <label for=\"id_realm_description\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":83},"end":{"line":36,"column":116}}}))
    + "</label>\n                    <textarea id=\"id_realm_description\" name=\"realm_description\" class=\"admin-realm-description setting-widget prop-element settings_textarea\"\n                      maxlength=\"1000\" data-setting-widget-type=\"string\">"
    + alias2(alias4(alias3(depth0, "realm_description", {"start":{"line":38,"column":76},"end":{"line":38,"column":93}} ), depth0))
    + "</textarea>\n                </div>\n            </div>\n        </div>\n\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":13},"end":{"line":43,"column":50}}}))
    + "</div>\n        <div class=\"realm-icon-section\">\n            "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),undefined,{"name":"image_upload_widget","hash":{"image":lookupProperty(depth0,"realm_icon_url"),"is_editable_by_current_user":lookupProperty(depth0,"is_admin"),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete icon",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":45}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload icon",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":47,"column":45}}}),"widget":"realm-icon"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"brand","attention":"quiet","icon":"external-link","id":"id_org_profile_preview","custom_classes":"block","label":lookupProperty(helpers,"t").call(alias1,"Preview organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":53,"column":50}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <div class=\"subsection-header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":61,"column":42}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/create-your-organization-profile#add-a-wide-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A wide image (200×25 pixels) for the upper left corner of the app.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":11},"end":{"line":67,"column":86}}}))
    + "</p>\n        <div class=\"realm-logo-group\">\n            <div class=\"realm-logo-block realm-logo-section\">\n                <h5 class=\"realm-logo-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Light theme logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":45},"end":{"line":70,"column":70}}}))
    + "</h5>\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),undefined,{"name":"image_upload_widget","hash":{"image":lookupProperty(depth0,"realm_logo_url"),"is_editable_by_current_user":lookupProperty(depth0,"user_can_change_logo"),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":74,"column":49}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":73,"column":49}}}),"widget":"realm-day-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"realm-logo-block realm-logo-section\">\n                <h5 class=\"realm-logo-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dark theme logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":45},"end":{"line":79,"column":69}}}))
    + "</h5>\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),undefined,{"name":"image_upload_widget","hash":{"image":lookupProperty(depth0,"realm_night_logo_url"),"is_editable_by_current_user":lookupProperty(depth0,"user_can_change_logo"),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":49}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":32},"end":{"line":82,"column":49}}}),"widget":"realm-night-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <h3 class=\"light\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":89,"column":44}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/deactivate-your-organization"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </h3>\n        <div class=\"deactivate-realm-section\">\n            <div class=\"input-group\">\n                <div id=\"deactivate_realm_button_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":80},"end":{"line":94,"column":135}}})) != null ? stack1 : "")
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"custom_classes":"deactivate_realm_button","intent":"danger","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":28},"end":{"line":96,"column":57}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});