var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"add_subscriber_button_wrapper inline-block\">\n            "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"type":"submit","intent":"brand","attention":"quiet","custom_classes":"add-subscriber-button add-users-button","label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":29}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add_subscribers_container add-button-container\">\n    <div class=\"pill-container person_picker\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add subscribers. Use usergroup or #channelname to bulk add subscribers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":108}}}))
    + "\"></div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"hide_add_button"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":31}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});