var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "    <li class=\"view_user_profile\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":2,"column":50},"end":{"line":2,"column":57}} ), depth0))
    + "\" tabindex=\"0\" role=\"button\">\n        <img class=\"read_receipts_user_avatar\" src=\""
    + alias3(alias2(alias1(depth0, "avatar_url", {"start":{"line":3,"column":54},"end":{"line":3,"column":64}} ), depth0))
    + "\" />\n        <span>"
    + alias3(alias2(alias1(depth0, "full_name", {"start":{"line":4,"column":16},"end":{"line":4,"column":25}} ), depth0))
    + "</span>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"users"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":9}}})) != null ? stack1 : "");
},"useData":true});